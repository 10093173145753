import React from 'react';
import Axios from 'axios';
import { isMobile } from "react-device-detect";
import Select from "react-dropdown-select";
import API_URL from '../Api/ApiUrl';

// import './About.css';

class About extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            
        }
    }



    render() {
        const { productList } = this.state;
        return (

            <div>

            </div>
        );
    }
}
export default About;