import React from 'react';
import Axios from 'axios';
import { Button, Input, Container, Row, Col, Form, FormGroup, Label } from 'reactstrap';
import { Modal as ModalStrap, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Flexbox from 'flexbox-react';
import { isMobile } from "react-device-detect";
import API_URL from '../Api/ApiUrl';
import Modal from 'react-modal';
import notepen from '../../assets/images/notepen.png';
import './Request.css';
import zIndex from '@material-ui/core/styles/zIndex';

Modal.setAppElement('*');

const customStyles = {
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(110, 110, 110, 0.65)',
        zIndex: 1500,
    },
    content: {
        width: 300,
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        padding: 10,
        borderRadius: 10
    }
};

const ctlogStyles = {
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(110, 110, 110, 0.65)',
        zIndex: 1500,
    },
    content: {
        width: 300,
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        padding: 5,
        borderRadius: 10
    }
};

class Request extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            user_id: window.localStorage.getItem('_userId'),
            seller_id: 0,
            reqId: this.props.reqId,
            sellerBase64: null,
            purchaseInfo: '',
            imageSource: null,
            photo: null,
            phoneNumber: '',
            textPhoneNumber: '',
            imgInvce: null,
            noteModalVisible: false,
            ctlugModalVisible: false,
            txtList: '',
            txtAddress: '',
            showCtlogu: false,
            imgCtlgu: null,
        }

        this.handleInputChange = this.handleInputChange.bind(this);
    }

    componentDidMount() {
        const reqId = this.props.reqId;
        Axios.get(API_URL + 'getPurchaseInfo/' + reqId)
            .then(response => {
                console.log(response.data);
                this.setState({ purchaseInfo: response.data });
                this.setState({ seller_id: response.data.seller_id });
                this.setState({ phoneNumber: 'tel:' + response.data.mobile });
                this.setState({ textPhoneNumber: response.data.mobile });

                Axios.get(API_URL + 'getUserAvatar/S' + this.state.purchaseInfo.seller_id)
                    .then(response => {
                        this.setState({ sellerBase64: response.data.image });
                    }).catch(error => {
                        console.log(error);
                    });

                this.checkSellerCatalogue(this.state.purchaseInfo.seller_id);

            }).catch(error => {
                console.log(error);
            });
    }

    handleInputChange(eventData) {
        const target = eventData.target;
        const value = target.value;
        const name = target.name;
        this.setState({ [name]: value })
    }

    handleSendNote() {
        const note = {
            'request_id': this.state.reqId,
            'customer_id': this.state.user_id,
            'text': this.state.txtList,
            'address': this.state.txtAddress
        }

        console.log(note);

        Axios({
            method: 'post',
            url: API_URL + 'savePurchaseList',
            timeout: 8000,
            headers: { "Content-Type": "application/json" },
            data: note
        })
            .then(response => {
                console.log("-------");
                console.log(response.data);
                this.setState({ noteModalVisible: false });
            })
            .catch(error => console.error(error))
    }

    handleDoCancel() {
        clearInterval(this.intrvlId);
        this.setState({ headerVisible: true });
        this.setState({ cancelModalVisible: false });
        this.setState({ rqustVisible: false });
        this.setState({ runTimer: false });
        const log = new FormData()
        log.append('event_id', '5');
        log.append('agent', 'C');
        log.append('customer_id', this.state.user_id);
        log.append('seller_id', '0');
        log.append('request_id', this.state.reqId);
        log.append('score', '0');

        console.log(log);
        this.recordLog(log);
    }

    recordLog = (log) => {
        console.log(log);
        Axios({
            url: API_URL + 'recordLog',
            method: 'POST',
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            data: log,
        })
            .then(function (response) {
                console.log(response);
                console.log(response.data);
            })
            .catch(function (err) {
                console.log(err);
            });
    }

    checkSellerCatalogue(sellerId) {
        Axios.get(API_URL + 'isCatalogueExist/' + sellerId)
            .then(response => {
                console.log("xxxxxxxxxxxxx");
                console.log(response.data);
                var rspns = response.data.message;
                if (rspns === 1) {
                    this.setState({ showCtlogu: true });
                    this.getCataluge(sellerId);
                } else if (rspns === 0) {
                    this.setState({ showCtlogu: false });
                }
            }).catch(error => {
                console.log(error);
            });
    }

    getCataluge(sellerId) {
        Axios({
            url: API_URL + 'getCataluge/' + sellerId,
            method: 'GET',
            responseType: 'blob', // important
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            this.setState({ imgCtlgu: url });
        });
    }

    handleCancel() {
        this.setState({ cancelModalVisible: true });
        this.setState({ modalTitle: 'لغو درخواست' });
        this.setState({ modalSubTitle: 'برای لغو این درخواست مطمئن هستید؟' });
    }

    closeCancelModal() {
        this.setState({ cancelModalVisible: false });
    }

    openListNote() {
        this.setState({ noteModalVisible: true });
    }

    closeListNote() {
        this.setState({ noteModalVisible: false });
    }

    openCatalug() {
        this.setState({ ctlugModalVisible: true });
    }

    closeCatalug() {
        this.setState({ ctlugModalVisible: false });
    }

    render() {

        const styles = {
            family: {
                textAlign: 'right',
                fontFamily: 'sans-bold',
                fontSize: 14,
                margin: 5,
                color: 'black',
                paddingRight: 10,
            },
            itemspan: {
                textAlign: 'right',
                fontFamily: 'sans',
                fontSize: 14,
                color: '#454545',
                margin: 6,
                paddingRight: 10,
            },
            invoice: {
                width: 220,
                height: 300,
                borderWidth: 1,
                borderColor: 'gray',
                marginTop: 10,
            },
        };

        return (
            <div id="reqMain" style={{ width: isMobile ? '100%' : 400, margin: '0 auto' }}>
                <Flexbox flexDirection="column" minWidth={isMobile ? '100vw' : '10vw'}>
                    <Flexbox flexGrow={1} flexDirection="column" style={{ padding: 25 }}>
                        <div style={{ textAlign: 'center' }}>

                            {this.state.sellerBase64 ? <img className="sellerImage" alt="" src={`data:image/png;base64,${this.state.sellerBase64}`} /> :
                                <img className="sellerImage" src={require('../../assets/images/unknown.png')} />}

                            <div style={{
                                width: '98%',
                                borderBottomColor: 'gray',
                                borderBottomWidth: 1,
                                margin: 5
                            }} />
                        </div>
                        <div style={{ direction: 'rtl', textAlign: 'center' }}>
                            <span style={{ fontWeight: 'bold' }}>{this.state.purchaseInfo.shop}</span>
                        </div>
                        <div style={{ width: '98%', direction: 'rtl', textAlign: 'right' }}>
                            <span>خرید از: {this.state.purchaseInfo.product}</span><br />
                            <span>فروشنده: {this.state.purchaseInfo.family}</span><br />
                            <span>آدرس: {this.state.purchaseInfo.address}</span><br />
                            <span>تاریخ درخواست: {this.state.purchaseInfo.date}</span><br />
                        </div>

                    </Flexbox>

                    <Flexbox flexGrow={2} justifyContent="center" alignItems="center">
                        <div style={{ direction: 'rtl', textAlign: 'center' }}>
                            {isMobile ? (
                                <a href={this.state.phoneNumber}>
                                    <div className="btnCallGray">
                                        <span style={{ marginRight: 10, color: '#444' }}>تماس با فروشنده</span>
                                    </div></a>
                            ) : (
                                    <span style={{ marginRight: 10, color: '#444', fontWeight: 'bold' }}>شماره تماس فروشنده: {this.state.textPhoneNumber}</span>
                                )}
                        </div>
                    </Flexbox>

                    <Flexbox flexGrow={2} justifyContent="center" alignItems="center">
                        <div style={{ direction: 'rtl', textAlign: 'center' }}>
                            <div className="btnCtlgu" onClick={this.openCatalug.bind(this)}>
                                <span style={{ marginRight: 10, color: '#444' }}>فهرست کالا و خدمات</span>
                            </div>
                        </div>
                    </Flexbox>

                    <Flexbox flexGrow={2} justifyContent="center">
                        <Button onClick={this.handleCancel.bind(this)} style={{ width: 120, height: 30, backgroundColor: '#F0AA47', fontSize: 12, margin: 10 }}>لغو درخواست</Button>
                    </Flexbox>

                    <Flexbox flexGrow={2} flexDirection="column" justifyContent="center" alignItems="center">
                        <div onClick={this.openListNote.bind(this)} style={{ paddingLeft: 10, paddingTop: 10 }}>
                            <img src={notepen} width="50" height="50" alt="" />
                        </div>
                        <span style={{ fontWeight: 'bold', fontSize: 10 }}>لیست خرید</span>
                    </Flexbox>

                    {/* cancelModal */}
                    <Modal
                        isOpen={this.state.cancelModalVisible}
                        style={customStyles}>
                        <Container>
                            <Row>
                                <Col style={{ alignSelf: 'center', textAlign: 'center', direction: 'rtl' }}>{this.state.modalTitle}</Col>
                            </Row>
                            <Row>
                                <Col style={{ alignSelf: 'center', textAlign: 'center', direction: 'rtl' }}>{this.state.modalSubTitle}</Col>
                            </Row>
                            <Row style={{ paddingTop: 20 }}>
                                <Col xs="6" style={{ textAlign: 'center' }}><Button onClick={this.closeCancelModal.bind(this)} style={{ backgroundColor: 'gray' }}>خیر</Button></Col>
                                <Col xs="6" style={{ textAlign: 'center' }}><Button onClick={this.handleDoCancel.bind(this)} style={{ backgroundColor: '#0635c9' }}>بله</Button></Col>
                            </Row>
                        </Container>
                    </Modal>
                </Flexbox>

                {/* noteModal */}

                {/* <ModalStrap isOpen={this.state.noteModalVisible} className="modalStyle">
                    <ModalBody>
                        <Row>
                            <Col style={{ alignSelf: 'center', textAlign: 'center', direction: 'rtl', paddingTop: 20 }}>
                                برای حذف این اقدام مطمئن هستید؟
</Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Row style={{ paddingTop: 20 }}>
                            <Col xs="6" style={{ textAlign: 'center' }}><Button style={{ width: 80, backgroundColor: '#880000', color: '#fff' }}>بله</Button></Col>
                            <Col xs="6" style={{ textAlign: 'center' }}><Button style={{ width: 80, backgroundColor: 'gray', color: '#fff' }}>خیر</Button></Col>
                        </Row>
                    </ModalFooter>
                </ModalStrap> */}

                <Modal
                    isOpen={this.state.noteModalVisible}
                    style={customStyles}>
                    <Form>
                        <Container>
                            <Row style={{ backgroundColor: '#0635c9', height: 30, color: '#fff' }}>
                                <Col xs={10} style={{ alignSelf: 'center', textAlign: 'center', direction: 'rtl' }}>
                                    ارسال لیست خرید
                                </Col>
                                <Col xs={2} style={{ alignSelf: 'right', textAlign: 'right', direction: 'rtl' }}>
                                    <Button onClick={this.closeListNote.bind(this)} style={{ backgroundColor: '#efefef', color: '#808080' }}>X</Button>
                                </Col>
                            </Row>
                            <Row>
                                <Col style={{ alignSelf: 'center', textAlign: 'right', direction: 'rtl', paddingTop: 15 }}>
                                    <FormGroup>
                                        <Label>لیست خرید:</Label>
                                        <Input type="textarea" name="txtList" id="txtList" onChange={this.handleInputChange} value={this.state.txtList} style={{ height: 180 }} />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col style={{ alignSelf: 'center', textAlign: 'right', direction: 'rtl' }}>
                                    <FormGroup>
                                        <Label>آدرس:</Label>
                                        <Input type="textarea" name="txtAddress" id="txtAddress" onChange={this.handleInputChange} value={this.state.txtAddress} />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row style={{ paddingTop: 20 }}>
                                <Col style={{ textAlign: 'center' }}><Button onClick={this.handleSendNote.bind(this)} style={{ backgroundColor: '#0635c9' }}>ارسال به فروشنده</Button></Col>
                            </Row>
                        </Container>
                    </Form>
                </Modal>

                <Modal
                    isOpen={this.state.ctlugModalVisible}
                    style={ctlogStyles}>
                    <Container>
                        <Row style={{ backgroundColor: '#0635c9', height: 30, color: '#fff' }}>
                            <Col xs={10} style={{ alignSelf: 'center', textAlign: 'center', direction: 'rtl' }}>
                                کاتالوگ کالا و خدمات
                                </Col>
                            <Col xs={2} style={{ alignSelf: 'right', textAlign: 'right', direction: 'rtl' }}>
                                {this.state.showCtlogu ? (
                                    <Button onClick={this.closeCatalug.bind(this)} style={{ backgroundColor: '#efefef', color: '#808080' }}>X</Button>
                                ) : null}
                            </Col>
                        </Row>
                        <Row>
                            <Col style={{ height: 500, alignSelf: 'center', textAlign: 'right', direction: 'rtl', paddingTop: 15 }}>
                                <img src={this.state.imgCtlgu} alt="" style={{width: '100%'}} />
                            </Col>
                        </Row>
                    </Container>
                </Modal>
            </div>
        );
    }
}
export default Request;