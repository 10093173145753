import React from 'react';
import Axios from 'axios';
import { Button, Form, Input, Table } from 'reactstrap';
import Flexbox from 'flexbox-react';
import invitepic from '../../assets/images/invitepic.png';
import { isMobile } from "react-device-detect";
import { ToastsContainer, ToastsStore, ToastsContainerPosition } from 'react-toasts';
import API_URL from '../Api/ApiUrl';
import './Credit.css';

function randomstring(L) {
    var s = '';
    var randomchar = function () {
        var n = Math.floor(Math.random() * 62);
        if (n < 10) return n; //1-10
        if (n < 36) return String.fromCharCode(n + 55); //A-Z
        return String.fromCharCode(n + 61); //a-z
    }
    while (s.length < L) s += randomchar();
    return s;
}

class Credit extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            user_id: window.localStorage.getItem('_userId'),
            amount: 0,
            name: window.localStorage.getItem('_userName'),
            family: window.localStorage.getItem('_userFamily'),
            credit: 0,
        }
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    componentDidMount() {
        this.getUserCredit();
    }

    getUserCredit() {
        Axios.get(API_URL + 'getUserCash/' + window.localStorage.getItem('_userId'))
            .then(response => {
                console.log(response.data);
                this.setState({ credit: response.data.user_cash });
            }).catch(error => {
                console.log(error);
            });
    }

    handleInputChange(eventData) {
        const target = eventData.target;
        const value = target.value;
        const name = target.name;
        this.setState({ [name]: value })
    }

    goBankPage = () => {
        const _value1 = window.localStorage.getItem('_userId');
        console.log(_value1);

        let valAmount = this.state.amount;

        if (valAmount != "") {
            const BANK_URL = "https://sep.shaparak.ir/";
            const _url = BANK_URL +
                "Payment.aspx?MID=31060470&Amount=" + valAmount +
                "&ResNum=" + _value1 + "KB" + randomstring(20) +
                "&ResNum1=" + _value1 +
                "&RedirectUrl=https://kalabisim.com/home";
            const newWindow = window.open();
            newWindow.location.href = _url;
            //   } else {

        }
    }

    render() {
        return (
            <div style={{ width: isMobile ? '100%' : 450 }}>

                <Flexbox flexDirection="column" minHeight="100vh" minWidth={isMobile ? '100vw' : '10vw'}>
                    <Flexbox flexGrow={1} flexDirection="column">
                        <div style={{ width: '99%', padding: 20, textAlign: 'right' }}>
                            <span>نام:{'  '}{this.state.name}</span><br />
                            <span>نام خانوادگی:{'  '}{this.state.family}</span><br />
                            <span>اعتبار فعلی شما:{' '}{this.state.credit}{' '}ریال</span><br /><br />
                            <hr />
                        </div>
                    </Flexbox>

                    <Flexbox flexGrow={0.5} justifyContent="center" alignItems="center">
                        <Form>
                            <Table borderless style={{ width: '100%' }}>
                                <thead>
                                    <tr>
                                        <th colspan="3" style={{ textAlign: 'right', direction: 'rtl' }}>افزایش اعتبار:</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td style={{ verticalAlign: 'center' }}>ریال</td>
                                        <td>
                                            <Input type="number" name="amount" id="amount" onChange={this.handleInputChange} style={{ width: 100, margin: 5, fontFamily: 'sans-bold' }} />
                                        </td>
                                        <td style={{ verticalAlign: 'center', direction: 'rtl' }}>مبلغ مورد نظر:</td>
                                    </tr>
                                    <tr>
                                        <td colspan="3" style={{ textAlign: 'center', height: 100 }}>
                                            <Button onClick={this.goBankPage} style={{ width: 110, height: 35, backgroundColor: '#0635c9' }}>پرداخت</Button>
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Form>
                    </Flexbox>

                    <Flexbox flexGrow={2} justifyContent="center">
                        <ToastsContainer store={ToastsStore} position={ToastsContainerPosition.BOTTOM_CENTER} />
                    </Flexbox>
                </Flexbox>
            </div>
        );
    }
}
export default Credit;