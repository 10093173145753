import React from 'react';
import Axios from 'axios';
import API_URL from '../Api/ApiUrl';
import { isMobile } from "react-device-detect";

//import API_URL from '../Api/ApiUrl';
import './List.css';

class List extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            userId: window.localStorage.getItem('_userId'),
            requestList: [],

        }
    }

    componentDidMount() {
        const { userId } = this.state;
        Axios.get(API_URL + 'getCustomerRequestList/' + userId)
            .then(response => {
                console.log(response.data);
                this.setState({ requestList: response.data });
            }).catch(error => {
                console.log(error);
            });
    }

    render() {
        const { requestList } = this.state;
        return (
            <div style={{ width: isMobile ? '100%' : 450 }}>
                <div className="cardBody">
                    {requestList.map((item) => {
                        return (<div className="cardStyle" key={item.id} onClick={() => this.props.handleSelectPage(9, item.id)}>
                            <div className="innerCardGreen">
                                <div className="itemText">درخواست خرید از: {item.product} ({item.type})</div>
                                <div className="family">فروشنده: {item.seller}</div>
                                <div className="family">{item.shop}</div>
                                <div className="itemText">تاریخ درخواست: {item.date}</div>
                                <div className="itemText">زمان درخواست: {item.time}</div>
                            </div></div>)
                    })}
                </div>
            </div>
        );
    }
}
export default List;