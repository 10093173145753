import React from 'react';
import Flexbox from 'flexbox-react';
import Axios from 'axios';
import API_URL from '../Api/ApiUrl';
import { isMobile } from "react-device-detect";
import { Table, Label } from 'reactstrap';
import './Catalogue.css';

class Catalogue extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            seller_id: this.props.acceptorId,
            imgSrc: null,
            selectedFile: null,
            isSaved: false,
        }
    }

    componentDidMount() {
        this.getCataluge();
    }

    getCataluge() {
        Axios({
            url: API_URL + 'getCataluge/' + window.localStorage.getItem('_userId'),
            method: 'GET',
            responseType: 'blob', // important
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            this.setState({ imgSrc: url });
        });
    }

    render() {
        const styles = {
            invoice: {
                width: 300,
                height: 400,
                // borderWidth: 1,
                // borderColor: 'gray',
                marginTop: 10,

            },
        };
        return (
            <div style={{ width: isMobile ? '100%' : 450, direction: 'rtl' }}>
                <Flexbox flexDirection="column" minWidth={isMobile ? '100vw' : '10vw'}>
                    <Flexbox flexGrow={1} flexDirection="column">
                        <Table style={{width: '100%'}}>
                            <tbody>
                                <tr>
                                    <td style={{ textAlign: 'center', padding: 10 }} colSpan="2">
                                        {this.state.imgSrc === null ?
                                            <img style={styles.invoice} alt="user invoice"
                                                src={require('../../assets/images/invoice.jpg')} /> :
                                            <img src={this.state.imgSrc} alt="" style={{width: '100%'}} />
                                        }
                                    </td>
                                </tr>
                              </tbody>
                        </Table>
                    </Flexbox>
                </Flexbox>
            </div>

        );
    }
}
export default Catalogue;