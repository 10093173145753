import React from 'react';
import Axios from 'axios';
import Flexbox from 'flexbox-react';
import { isMobile } from "react-device-detect";
import API_URL from '../Api/ApiUrl';
import './Message.css';

class Message extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            userId: '1',
            userType: '1', //for customer
            messagesList: [],
        }
    }

    componentDidMount() {
        const { userId } = this.state;
        const { userType } = this.state;
        Axios.get(API_URL + 'getMessageList/' + userType + '/' + userId)
            .then(response => {
                console.log(response.data);
                this.setState({ messagesList: response.data });
            }).catch(error => {
                console.log(error);
            });
    }

    render() {
        const { messagesList } = this.state;
        return (
            <div style={{ width: isMobile ? '100%' : 450 }}>
                <Flexbox flexDirection="column" minWidth={isMobile ? '100vw' : '10vw'}>
                    <Flexbox flexGrow={1}>
                        {messagesList.map((item) => {
                            return (<div className="msgeStyle" key={item.id}>
                                <div className="msgeText">{item.text}</div>
                                <div className="dateTime">
                                    <div className="dateText">{item.date}</div>
                                    <div className="dateText">{item.time}</div>
                                </div>
                            </div>)
                        })}
                    </Flexbox>
                </Flexbox>
            </div>
        );
    }
}
export default Message;