import React from 'react';
import Axios from 'axios';
import Flexbox from 'flexbox-react';
import {
    withScriptjs,
    withGoogleMap,
    GoogleMap,
    Marker,
} from "react-google-maps";
import {
    BrowserView,
    MobileView,
    isMobile,
    isSafari,
    isBrowser
} from "react-device-detect";
import DrawerMenu from '../Drawer';
import { Drawer } from 'react-pretty-drawer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import kbclogo from '../../assets/images/kbclogo.png';
import defProfile from '../../assets/images/profiledefault.png';
import Icon from "../Icons/icon";
import Autocomplete from '@material-ui/lab/Autocomplete';
// import AutoComplete from '../DropDown/AutoComplete';
// import Select from "react-dropdown-select";
import TextField from '@material-ui/core/TextField';
import { faIR } from '@material-ui/core/locale';
import { create } from 'jss';
import rtl from 'jss-rtl';
import { createMuiTheme, ThemeProvider, StylesProvider, jssPreset } from '@material-ui/core/styles';
import mapStyles from './mapStyles';
import API_URL from '../Api/ApiUrl';
import { ToastsContainer, ToastsStore, ToastsContainerPosition } from 'react-toasts';
import { Button, Input, Container, Row, Col, Form, FormGroup, Label } from 'reactstrap';
// import TimerMixin from 'react-timer-mixin';
import Modal from 'react-modal';
import Banner from './Banner';
import History from '../History';
import Purchase from '../Purchase';
import Cooperate from '../Cooperate';
import Message from '../Message';
import Invite from '../Invite';
import Support from '../Support';
import Profile from '../Profile';
import About from '../About';
import Credit from '../Credit';
import WebView from '../WebView';
import FeedBack from '../FeedBack';
import Catalogue from '../Catalogue';
import List from '../List';
import Request from '../Request';
import sellerpic from '../../assets/images/unknown.png';
import notepen from '../../assets/images/notepen.png';
import waitpic from '../../assets/images/cart_anim.gif';
import './Home.css';

Modal.setAppElement('*');
const theme = createMuiTheme({ direction: 'rtl' }, faIR);

// Configure JSS
const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

function RTL(props) {
    return (
        <StylesProvider jss={jss}>
            {props.children}
        </StylesProvider>
    );
}

const customStyles = {
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(110, 110, 110, 0.65)'
    },
    content: {
        width: 300,
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        padding: 10,
        borderRadius: 10
    }
};

const rqustStyles = {
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(110, 110, 110, 0.65)'
    },
    content: {
        width: 500,
        height: '100%',
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        padding: 10,
        borderRadius: 10
    }
};

// double latitude = 35.70092132333425;
// double longitude = 51.391260847449296;

class Home extends React.Component {

    constructor(props) {
        super(props);

        this.lastCenter = {
            lat: 35.70092132333425,
            lng: 51.391260847449296
        }

        this.feedBackPage = ""
        this.selectPage = ""
        this.pageTitle = ""
        this.intrvlId = null;

        this.state = {
            user_id: 0,
            req_id: 0,
            name: '',
            family: '',
            user_code: '',
            txtList: '',
            txtAddress: '',
            panelVisible: true,
            menuIsOpen: false,
            topTitle: 'کالابیسیم',
            panelStatus: 0,
            saleType: 0,
            namefamily: 'namefamily',
            productVisible: false,
            sellerList: [],
            productList: [],
            prodType: 0,
            activityTitles1: [],
            activityTitles2: [],
            activityTitles3: [],
            modalTitle: '',
            modalSubTitle: '',
            discountHeight: 80,
            requestVisible: false,
            discountVisible: false,
            optionVisible1: false,
            optionVisible2: false,
            profileModalVisible: false,
            confirmModalVisible: false,
            noteModalVisible: false,
            pageVisible: false,
            rqustVisible: false,
            profileStatus: 'none',
            isMapOpen: false,
            cancelModalVisible: false,
            sellerIds: '',
            sellerInfo: '',
            sellerMobile: '',
            runTimer: false,
            acceptorId: 0,
            acceptorData: '',
            mainMarker: 'modalMarker1',
            latValue: '35.70092132333425',
            lngValue: '51.391260847449296',
            mapCenter: {
                lat: 35.70092132333425,
                lng: 51.391260847449296
            },
            pages: [
                { id: 1, page: '/history', title: 'سابقه خرید', icon: 'history' },
                { id: 2, page: '/message', title: 'پیام ها', icon: 'bubbles4' },
                { id: 3, page: '/invite', title: 'دعوت از دوستان', icon: 'share2' },
                { id: 4, page: '/support', title: 'رسیدگی به شکایات', icon: 'phone' },
                { id: 5, page: '/profile', title: 'تنظیمات', icon: 'cog' },
                { id: 6, page: '/about', title: 'درباره ما', icon: 'info' },
                // { page: 'ExitApp', title: 'خروج', icon: 'power-settings-new' },
            ],
            avatarSource: null,
            avatarBase64: null,
            sellerBase64: null,
            productId: 0,
            creditVisible: true,
            myreqVisible: true,
            sellerFound: false,
            headerVisible: true,
            sellerVisible: false,
            isSetCurrent: false,
            currentPosition: {
                lat: 0,
                lng: 0
            },
            currentLatLng: {
                lat: 0,
                lng: 0
            },
            isMarkerShown: false,
            feedBackPage: '',
            showCtlogu: false,
            requestCount: 0,

            butnStatus1: true,
            butnStatus2: false,
            butnStatus3: false,
            butnStatus4: false,
            butnStatus5: false,

            userCredit: 0
        }

        this.timer = null;
        this.onSelectChange = this.onSelectChange.bind(this);
        this.handleRedirect = this.handleRedirect.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.closeFeedbackModal = this.closeFeedbackModal.bind(this);
    }

    loadUserInfo() {
        const value0 = window.localStorage.getItem('_userId');
        this.setState({ user_id: value0 });
        this.setState({ user_code: 'C' + value0 });
        const value1 = window.localStorage.getItem('_userName');
        const value2 = window.localStorage.getItem('_userFamily');
        this.setState({ namefamily: value1 + ' ' + value2 });
        Axios.get(API_URL + 'getUserAvatar/C' + value0)
            .then(response => {
                this.setState({ avatarBase64: response.data.image });
            }).catch(error => {
                console.log(error);
            });
    };

    handleClick() {
        console.log('cliked....');
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {
                this.setState(() => ({
                    isSetCurrent: true,
                    mapCenter: {
                        lat: position.coords.latitude,
                        lng: position.coords.longitude
                    },
                    currentPosition: {
                        lat: position.coords.latitude,
                        lng: position.coords.longitude
                    }
                }));
                this.lastCenter = this.state.currentPosition;
            });
        }
        else {
            console.log('Geoloaction is not supported by your browser');
        }
    }

    showCurrentLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                position => {
                    this.setState(prevState => ({
                        currentLatLng: {
                            ...prevState.currentLatLng,
                            lat: position.coords.latitude,
                            lng: position.coords.longitude
                        },
                        isMarkerShown: true
                    }))
                }
            )
        }
    }

    componentDidMount() {
        this.loadUserInfo();
        this.getRequestCount();
        this.getUserCash();
        this.getStatusPoll();
        this.showCurrentLocation();
    }

    getUserCash() {
        Axios.get(API_URL + 'getUserCash/' + window.localStorage.getItem('_userId'))
            .then(response => {
                console.log(response.data);
                this.setState({ userCredit: response.data.user_cash });
            }).catch(error => {
                console.log(error);
            });
    }

    getProductList(pType) {
        Axios.get(API_URL + 'getProductList/' + pType)
            .then(response => {
                console.log(response.data);
                this.setState({ productList: response.data });
            }).catch(error => {
                console.log(error);
            });
    }

    onSelectChange = (event, values) => {
        if (values != null) {
            this.setState({
                tags: values.id
            }, () => {
                console.log(this.state.tags);
                if (this.state.tags !== 0) {
                    this.getSellers(this.state.tags);
                }
            })
        }
    }

    getRequestCount() {
        var custId = window.localStorage.getItem('_userId');
        Axios.get(API_URL + 'getRequestCount/' + custId)
            .then(response => {
                console.log(response.data);
                this.setState({ requestCount: response.data });
            }).catch(error => {
                console.log(error);
            });
    }

    getStatusPoll() {
        var custId = window.localStorage.getItem('_userId');
        Axios.get(API_URL + 'getStatusPoll/' + custId)
            .then(response => {
                console.log(response.data);
                var req_id = response.data.req_id;
                if (req_id !== 0) {
                    this.pageTitle = 'بازخورد خرید';
                    this.feedBackPage = <FeedBack reqId={req_id} closeFeedbackModal={this.closeFeedbackModal} />
                    this.setState({ feedbackVisible: true });
                }
            }).catch(error => {
                console.log(error);
            });
    }

    toggle() {
        const { menuIsOpen } = this.state;
        this.setState({ myreqVisible: false })
        this.setState({ creditVisible: false })
        this.setState({ menuIsOpen: !menuIsOpen })
    }

    closeMenu() {
        this.setState({ menuIsOpen: false })
        if (this.state.panelStatus === 0) {
            this.setState({ creditVisible: true })
            this.setState({ myreqVisible: true })
        }
    }

    closePage() { this.setState({ pageVisible: false }) }

    toggleFrontPanel = (action) => {
        if (action === 0) {
            this.setState({ panelVisible: true });
        }
        if (action === 1) {
            this.setState({ panelVisible: false });
        }
    }

    changePanelStatus = (status) => {
        switch (status) {
            case 0:
                this.toggleFrontPanel(0);
                this.setState({ panelStatus: status });
                this.setState({ saleType: status });
                this.setState({ isMapOpen: false });
                this.setState({ requestVisible: false });
                this.setState({ productVisible: false });
                this.setState({ myreqVisible: true })
                this.setState({ creditVisible: true })
                this.setState({ mainMarker: 'modalMarker1' });
                this.setState({ topTitle: 'کالا بیسیم' });
                break;
            case 1:
                this.toggleFrontPanel(1);
                this.setState({ myreqVisible: false })
                this.setState({ creditVisible: false })
                this.setState({ discountVisible: false });
                this.setState({ optionVisible1: false });
                this.setState({ optionVisible2: false });
                this.setState({ panelStatus: status });
                this.setState({ requestVisible: false });
                this.setState({ productVisible: false });
                this.setState({ sellerVisible: false });
                this.setState({ isMapOpen: true });
                this.setState({ mainMarker: 'modalMarker2' });
                this.setState({ sellerList: [] });
                this.setState({ topTitle: 'کجا هستید؟' });
                break;
            case 2:
                this.setState({ panelStatus: status });
                this.setState({ productVisible: true });
                this.setState({ requestVisible: false });
                this.setState({ sellerVisible: false });
                this.setState({ topTitle: 'چه می خواهید؟' });
                break;
            case 3:
                this.setState({ panelStatus: status });
                this.setState({ productVisible: false });
                this.setState({ topTitle: 'فروشنده' });
                break;
            default:
        }
    }

    setProdType(pType) {
        console.log("pType: " + pType);
        this.setState({ prodType: pType });
        this.getProductList(pType);
        this.changePanelStatus(1);
        if (pType == 1)
            this.setSaleType(3);
        else
            this.setSaleType(1);
    }

    setSaleType(sType) {
        this.setState({ saleType: sType });

        switch (sType) {
            case 1:
                this.setState({ butnStatus1: true });
                this.setState({ butnStatus2: false });
                this.setState({ butnStatus3: false });
                this.setState({ butnStatus4: false });
                this.setState({ butnStatus5: false });
                break;
            case 2:
                this.setState({ butnStatus1: false });
                this.setState({ butnStatus2: true });
                this.setState({ butnStatus3: false });
                this.setState({ butnStatus4: false });
                this.setState({ butnStatus5: false });
                break;
            case 3:
                this.setState({ butnStatus1: false });
                this.setState({ butnStatus2: false });
                this.setState({ butnStatus3: true });
                this.setState({ butnStatus4: false });
                this.setState({ butnStatus5: false });
                break;
            case 4:
                this.setState({ butnStatus1: false });
                this.setState({ butnStatus2: false });
                this.setState({ butnStatus3: false });
                this.setState({ butnStatus4: true });
                this.setState({ butnStatus5: false });
                break;
            case 5:
                this.setState({ butnStatus1: false });
                this.setState({ butnStatus2: false });
                this.setState({ butnStatus3: false });
                this.setState({ butnStatus4: false });
                this.setState({ butnStatus5: true });
                break;
        }
    }

    handlePanelStatus() {
        if (this.state.panelStatus === 3) {
            this.changePanelStatus(2);
        }

        if (this.state.panelStatus === 2) {
            this.changePanelStatus(1);
        }

        if (this.state.panelStatus === 1) {
            this.changePanelStatus(0);
        }
    }

    async getSellers(itemId) {
        const { sellerList } = this.state;
        try {
            const { data } = await Axios.get(API_URL + 'getOnlineSellers/' + itemId + "/" + this.state.prodType + "/" + this.lastCenter.lat + "/" + this.lastCenter.lng);
            console.log(data.length);
            console.log(data);
            if (data.length > 0) {
                this.setState({ sellerList: data });
                this.setState({ productVisible: false });
                this.setState({ requestVisible: true });
                this.setState({ productId: itemId });
                this.changePanelStatus(3);

                //create sellerIds
                var strSlrids = '';
                for (let i = 0; i < data.length; i++) {
                    var obj = data[i];
                    strSlrids = strSlrids + ',' + obj.id
                }
                strSlrids = strSlrids.substr(1);
                this.setState({ sellerIds: strSlrids });
            } else {
                this.setState({ sellerList: [] });
                this.setState({ requestVisible: false });
                ToastsStore.warning('در حال حاضر فروشنده ای پاسخگو نیست');
            }
        } catch (err) {
            console.log(err.message)
        }
    }

    handleRedirect(pageId) {
        this.props.history.push({ pathname: '/web', page_id: pageId });
        console.log(pageId)
    }

    doToggleDiscount = () => {
        const { discountVisible } = this.state;
        if (discountVisible) {
            this.setState({ discountVisible: false });
            // this.setState({ subHeight: 90 });
        } else {
            this.setState({ discountVisible: true });
            this.setState({ optionVisible1: false });
            this.setState({ optionVisible2: false });
            // this.setState({ subHeight: 140 });
        }
    }

    doToggleOption = () => {
        const { prodType, optionVisible1, optionVisible2 } = this.state;
        if (prodType === 1) {
            if (optionVisible1) {
                this.setState({ optionVisible1: false });
            } else {
                this.setState({ optionVisible1: true });
                this.setState({ discountVisible: false });
            }
        } else {
            if (optionVisible2) {
                this.setState({ optionVisible2: false });
            } else {
                this.setState({ optionVisible2: true });
                this.setState({ discountVisible: false });
            }
        }
    }

    checkProfileAndSend = () => {
        const prfStatus = window.localStorage.getItem('_profileStatus');
        this.setState({ profileStatus: prfStatus });
        if (prfStatus === 'ok') {
            this.setState({ modalTitle: 'ارسال درخواست' });
            this.setState({ modalSubTitle: 'برای ارسال این درخواست مطمئن هستید؟' });

        } else {
            this.setState({ modalTitle: 'برای ارسال درخواست، حتماً باید پروفایلتون تکمیل باشه!' });
            this.setState({ modalSubTitle: 'می خواین پروفایلتون رو تکمیل کنید؟' });
        }
        this.setState({ profileModalVisible: true });
    }

    handleModalResponse() {
        if (this.state.profileStatus === 'ok') {
            this.doAcceptRquest();
        } else {
            this.setState({ profileModalVisible: false });
            this.handleSelectPage(5);
        }
    }

    handleSelectPage = (pageId, rqstId) => {
        switch (pageId) {
            case 1:
                this.pageTitle = 'سابقه خرید';
                this.selectPage = <History handleSelectPage={this.handleSelectPage} />
                break;
            case 2:
                this.pageTitle = 'پیام ها';
                this.selectPage = <Message />
                break;
            case 3:
                this.pageTitle = 'دعوت از دوستان';
                this.selectPage = <Invite />
                break;
            case 4:
                this.pageTitle = 'رسیدگی به شکایات';
                this.selectPage = <Support />
                break;
            case 5:
                this.pageTitle = 'پروفایل کاربر';
                this.selectPage = <Profile />
                break;
            // case 6:
            //     this.pageTitle = 'درباره ما';
            //     this.selectPage = <About />
            //     break;
            case 7:
                this.pageTitle = 'اطلاعات خرید';
                this.selectPage = <Purchase reqId={rqstId} />
                break;
            case 8:
                this.pageTitle = 'کاتالوگ کالا و خدمات';
                this.selectPage = <Catalogue acceptorId={this.state.acceptorId} />
                break;
            case 9:
                this.pageTitle = 'اطلاعات درخواست';
                this.selectPage = <Request reqId={rqstId} handleSelectPage={this.handleSelectPage} />
                break;
            case 10:
                this.pageTitle = 'درخواست همکاری';
                this.selectPage = <Cooperate />
                break;
            case 11:
                this.pageTitle = 'خدمات بازاریابی و فروش';
                this.selectPage = <WebView page_id={11} />
                break;
            case 12:
                this.pageTitle = 'خدمات مشاوره کسب و کار';
                this.selectPage = <WebView page_id={12} />
                break;
            case 13:
                this.pageTitle = 'خدمات ورود به بازار';
                this.selectPage = <WebView page_id={13} />
                break;
            case 14:
                this.pageTitle = 'خدمات فناوری اطلاعات و ایزو';
                this.selectPage = <WebView page_id={14} />
                break;
            case 15:
                this.pageTitle = 'خدمات مالی و مالیاتی';
                this.selectPage = <WebView page_id={15} />
                break;
            case 16:
                this.pageTitle = 'خدمات حقوقی و قضایی';
                this.selectPage = <WebView page_id={16} />
                break;
            case 17:
                this.pageTitle = 'خدمات منابع انسانی';
                this.selectPage = <WebView page_id={17} />
                break;

            case 20:
                this.pageTitle = 'کیف پول';
                this.selectPage = <Credit />
                break;
            case 21:
                this.pageTitle = 'درخواست های من';
                this.selectPage = <List handleSelectPage={this.handleSelectPage} />
                break;

            case 51:
                this.pageTitle = 'قرعه کشی در کالابیسیم';
                this.selectPage = <WebView page_id={51} />
                break;
            case 6:
            case 52:
                this.pageTitle = 'آشنایی با کالابیسیم';
                this.selectPage = <WebView page_id={52} />
                break;
            case 53:
                this.pageTitle = 'روش کارکردن با کالابیسیم';
                this.selectPage = <WebView page_id={53} />
                break;
            case 54:
                this.pageTitle = 'خرید اقساطی در کالابیسیم';
                this.selectPage = <WebView page_id={54} />
                break;
            case 55:
                this.pageTitle = 'سرویس های کسب و کار کالابیسیم';
                this.selectPage = <WebView page_id={55} />
                break;
            case 56:
                this.pageTitle = 'بزودی ...';
                this.selectPage = <WebView page_id={56} />
                break;

            default:
                this.pageTitle = 'سایت کالابیسیم';
                this.selectPage = <WebView page_id={22} />
        }
        this.setState({ pageVisible: true })
    }

    doAcceptRquest = () => {
        this.setState({ runTimer: true });
        this.setState({ profileModalVisible: false });
        this.setState({ discountVisible: false });
        this.setState({ headerVisible: false });
        this.setState({ rqustVisible: true });

        const request = {
            'request_type': 1,
            'sale_type': this.state.saleType,
            'product_id': this.state.productId,
            'customer_id': this.state.user_id,
            'acceptor_id': 0,
            'seller_ids': this.state.sellerIds,
            'latitude': JSON.stringify(this.lastCenter.lat),
            'longitude': JSON.stringify(this.lastCenter.lng)
        }

        console.log(request);

        Axios({
            method: 'post',
            url: API_URL + 'sendRequest',
            timeout: 8000,
            headers: { "Content-Type": "application/json" },
            data: request
        })
            .then(response => {
                console.log("-------");
                console.log(response.data);
                this.setState({ req_id: response.data.request_id });
                this.intrvlId = setInterval(() => {
                    this.doSearchSellers();
                }, 3000);
            })
            .catch(error => console.error(error))
    }

    stopSearchSellers() {
        clearInterval(this.intrvlId);
    }

    closeModal() {
        this.setState({ profileModalVisible: false });
    }

    setMapCenter() {
        var myPlace = {
            id: 0,
            position: {
                latitude: JSON.stringify(this.lastCenter.lat),
                longitude: JSON.stringify(this.lastCenter.lng)
            }
        }
        if (this.state.isSetCurrent) {
            this.setState({ mapCenter: this.state.currentPosition })
        } else {
            this.setState({ mapCenter: this.lastCenter })
        }
        this.changePanelStatus(2);
    }

    renderChannelButtons() {
        return this.state.pages.map(({ id, page, title, icon }) => (
            <div onClick={() => this.handleSelectPage(id)} key={page} style={{ textAlign: 'right', padding: 10 }}>
                <span style={{ color: "#000", marginTop: 10, fontSize: 14, padding: 5 }}>
                    {title}  <Icon icon={icon} size={18} color="#000" /></span>
            </div>
        ));
    }

    recordLog = (log) => {
        console.log(log);
        Axios({
            url: API_URL + 'recordLog',
            method: 'POST',
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            data: log,
        })
            .then(function (response) {
                console.log(response);
                console.log(response.data);
            })
            .catch(function (err) {
                console.log(err);
            });
    }

    handleCancel() {
        this.setState({ cancelModalVisible: true });
        this.setState({ modalTitle: 'لغو درخواست' });
        this.setState({ modalSubTitle: 'برای لغو این درخواست مطمئن هستید؟' });
    }

    handleDoCancel() {
        clearInterval(this.intrvlId);
        this.setState({ headerVisible: true });
        this.setState({ cancelModalVisible: false });
        this.setState({ rqustVisible: false });
        this.setState({ runTimer: false });
        const log = new FormData()
        log.append('event_id', '5');
        log.append('agent', 'C');
        log.append('customer_id', this.state.user_id);
        log.append('seller_id', '0');
        log.append('request_id', this.state.req_id);
        log.append('score', '0');

        console.log(log);
        this.recordLog(log);
    }

    closeCancelModal() {
        this.setState({ cancelModalVisible: false });
    }

    doSearchSellers() {
        console.log("Hi....." + this.intrvlId);
        const { acceptorData } = this.state;

        console.log(API_URL + 'searchSellers/' + this.state.req_id + '/' + this.state.user_id);

        Axios.get(API_URL + 'searchSellers/' + this.state.req_id + '/' + this.state.user_id)
            .then(response => {
                console.log(response.data);
                this.setState({ acceptorData: response.data });
                this.setState({ acceptorId: response.data.id });
                if (acceptorData.count > 0) {
                    console.log("found");
                    this.setState({ sellerFound: true });
                    clearInterval(this.intrvlId);

                    //set seller data
                    this.setState({ sellerBase64: acceptorData.avatar });
                    this.setState({ sellerMobile: 'tel:' + acceptorData.mobile });
                }
            }).catch(error => {
                console.log(error);
            });
    }

    handleRealized() {
        this.setState({ rqustVisible: false });
        this.setState({ headerVisible: true });
        this.setState({ requestVisible: false });
        this.setState({ sellerVisible: true });

        this.checkSellerCatalogue();
    }

    checkSellerCatalogue() {
        Axios.get(API_URL + 'isCatalogueExist/' + this.state.acceptorData.id)
            .then(response => {
                console.log(response.data);
                var rspns = response.data.message;
                if (rspns === 1) {
                    this.setState({ showCtlogu: true });
                } else if (rspns === 0) {
                    this.setState({ showCtlogu: false });
                }
            }).catch(error => {
                console.log(error);
            });
    }

    openListNote() {
        this.setState({ noteModalVisible: true });
    }

    closeListNote() {
        this.setState({ noteModalVisible: false });
    }

    handleInputChange(eventData) {
        const target = eventData.target;
        const value = target.value;
        const name = target.name;
        this.setState({ [name]: value })
    }

    handleSendNote() {
        const note = {
            'request_id': this.state.req_id,
            'customer_id': this.state.user_id,
            'text': this.state.txtList,
            'address': this.state.txtAddress
        }

        console.log(note);

        Axios({
            method: 'post',
            url: API_URL + 'savePurchaseList',
            timeout: 8000,
            headers: { "Content-Type": "application/json" },
            data: note
        })
            .then(response => {
                console.log("-------");
                console.log(response.data);
                this.setState({ noteModalVisible: false });
            })
            .catch(error => console.error(error))
    }

    closeFeedbackModal(reqId, rateValue, fbType) {
        //set rating and poll result
        console.log("yyyyyyyyyyyyyyy");
        console.log(reqId);
        console.log(rateValue);

        const poll = {
            'request_id': reqId,
            'type': fbType,
            'feedback_ids': '1,2',
            'score': rateValue
        }

        console.log(poll);

        Axios({
            method: 'post',
            url: API_URL + 'savePoll',
            timeout: 8000,  // 8 seconds timeout
            headers: { "Content-Type": "application/json" },
            data: poll
        })
            .then(response => {
                console.log("-------");
                console.log(response.data);
                //set poll result ok
                Axios.put(API_URL + 'setStatusPoll/' + reqId)
                    .then(response => {
                        console.log(response.data);
                    }).catch(error => {
                        console.log(error);
                    });
            })
            .catch(error => console.error(error))
        this.setState({ feedbackVisible: false });
    }

    render() {
        let ref = null;
        let styles = {
            mapCloseStyle: {
                width: "100vw", height: "50vh"
            },
            mapOpenStyle: {
                width: "100vw", height: "100vh"
            },
        };

        let MapWrapped = withScriptjs(withGoogleMap(props => (
            <GoogleMap
                ref="map"
                ref={(mapRef) => ref = mapRef}
                onCenterChanged={() => {
                    if (this.state.panelStatus === 1) {
                        var center = ref.getCenter().toJSON();
                        console.log(center)
                        this.lastCenter = center;
                    }
                }}

                isMarkerShown={this.state.isMarkerShown}
                currentLocation={this.state.currentLatLng}

                defaultZoom={16}
                defaultCenter={this.state.mapCenter}
                options={{
                    styles: mapStyles,
                    mapTypeControl: false,
                    streetViewControl: false,
                    zoomControl: false,
                    fullscreenControl: false,
                }}>
                {this.state.panelStatus > 1 ? (
                    <Marker
                        mapTypeControl={false}
                        position={this.state.mapCenter}
                        icon={{
                            url: require("../../assets/images/pin_here_96.png"),
                            scaledSize: new window.google.maps.Size(39, 56),
                            anchor: new window.google.maps.Point(20, 28),
                        }}
                    />
                ) : null}
                {this.state.sellerList.map(seller => (
                    <Marker
                        key={seller.id}
                        mapTypeControl={false}
                        position={{
                            lat: parseFloat(seller.position.latitude),
                            lng: parseFloat(seller.position.longitude)
                        }}
                        icon={{
                            url: require("../../assets/svg/ic_map_pin.svg"),
                            scaledSize: new window.google.maps.Size(50, 70)
                        }}
                    />
                ))}
            </GoogleMap>)
        ));

        return (
            <div>
                {/* map OK */}
                <div style={isSafari ? this.state.isMapOpen ? styles.mapOpenStyle : styles.mapCloseStyle : styles.mapOpenStyle}>
                    <MapWrapped
                        googleMapURL={'https://maps.googleapis.com/maps/api/js?key=AIzaSyCqdq1BBP9hTxYACzXwv2x7uxDRGpjDeWw&v=3.exp&libraries=geometry,drawing,places&language=fa'}
                        loadingElement={<div style={{ height: '100%' }} />}
                        containerElement={<div style={{ height: '100%' }} />}
                        mapElement={<div style={{ height: '100%' }} />} />
                </div>

                {this.state.panelStatus < 2 ? (<div>
                    <div onClick={this.handleClick.bind(this)} className="locIcon">
                        <img src={require('../../assets/images/mylocblue.png')} alt="" style={{ width: 30, height: 30 }} />
                    </div>

                    <div onClick={this.setMapCenter.bind(this)} className={this.state.mainMarker}>
                        <img src={require('../../assets/images/myloction96.png')} alt="" style={{ width: 40, height: 58 }} />
                    </div>
                </div>
                ) : null}

                {this.state.productVisible ? (
                    <Flexbox flexDirection="row" direction="rtl" height="50px" minWidth="100vw" justifyContent="center" alignItems="center" style={{ position: 'absolute', top: 70, width: 250 }}>

                        {/* <Select
                            options={this.state.productList.bussines_title}
                            values={[]}
                            onChange={(values) => this.setValues(values)}
                            direction='rtl'
                            style={{ width: 250, backgroundColor: '#fff' }}
                        /> */}

                        <ThemeProvider theme={theme}>
                            <RTL>
                                <Autocomplete
                                    freeSolo
                                    id="combo-box-demo2"
                                    openOnFocus={true}
                                    //  onChange={(event, value) => console.log(value)}
                                    onChange={this.onSelectChange}
                                    options={this.state.productList}
                                    getOptionLabel={(option) => option.title}
                                    menuStyle={{ direction: 'rtl', fontSize: '12px' }}
                                    style={{ width: 250, backgroundColor: '#fff', direction: 'rtl' }}
                                    renderInput={(params) => <TextField {...params} label="انتخاب کنید" variant="outlined" style={{ direction: 'rtl' }} />} />
                            </RTL>
                        </ThemeProvider>
                    </Flexbox>
                ) : null}

                <ToastsContainer store={ToastsStore} position={ToastsContainerPosition.BOTTOM_CENTER} />

                {this.state.requestVisible ? (
                    <div style={{ position: 'absolute', bottom: 0, width: '100%', height: '${this.state.discountHeight}', border: '1px solid #888', backgroundColor: '#fff' }}>
                        <Flexbox flexDirection="column">
                            <Flexbox flexDirection="row" height="50px" minWidth="100vw">
                                <Flexbox flexGrow={1}>
                                    <Button onClick={this.doToggleOption} style={{ width: 90, height: 30, backgroundColor: '#0635c9', fontSize: 12, margin: 5 }}>گزینه ها</Button>
                                </Flexbox>
                                <Flexbox flexGrow={2} justifyContent="center" alignItems="center">
                                    <span style={{ verticalAlign: 'middle', fontSize: 14 }}>اعتبار: 0 ریال</span>
                                </Flexbox>
                                <Flexbox flexGrow={1} justifyContent="flex-end">
                                    <Button onClick={this.doToggleDiscount} style={{ width: 90, height: 30, backgroundColor: '#0635c9', fontSize: 12, margin: 5 }}>کد تخفیف</Button>
                                </Flexbox>
                            </Flexbox>
                            {this.state.discountVisible ? (
                                <Flexbox flexDirection="row" minWidth="100vw">
                                    <Flexbox flexGrow={1} justifyContent="center" alignItems="center">
                                        <Form inline>
                                            <Button type="button" onClick={this.doRegisterFunction} style={{ width: 80, height: 30, backgroundColor: '#0635c9', fontSize: 12, marginRight: 10 }}>ثبت</Button>
                                            <Input type="text" name="phoneNumber" id="phoneNumber" onChange={this.handleInputChange} maxLength="11" style={{ width: 110, height: 30 }} />
                                        </Form>
                                    </Flexbox>
                                </Flexbox>
                            ) : null}
                            {this.state.optionVisible1 ? (
                                <Flexbox flexDirection="row" minWidth="100vw">
                                    <Flexbox flexGrow={1} justifyContent="center" alignItems="center">
                                        <Form inline>
                                            <Button type="button" onClick={() => this.setSaleType(5)} style={{ width: 110, height: 30, color: '#0635c9', backgroundColor: this.state.butnStatus5 ? '#ffffcc' : 'transparent', fontSize: 12, marginRight: 20 }}>اعتباری</Button>
                                            <Button type="button" onClick={() => this.setSaleType(4)} style={{ width: 110, height: 30, color: '#0635c9', backgroundColor: this.state.butnStatus4 ? '#ffffcc' : 'transparent', fontSize: 12 }}>با کارت خوان</Button>
                                            <Button type="button" onClick={() => this.setSaleType(3)} style={{ width: 110, height: 30, color: '#0635c9', backgroundColor: this.state.butnStatus3 ? '#ffffcc' : 'transparent', fontSize: 12, marginLeft: 20 }}>پرداخت نقدی</Button>
                                        </Form>
                                    </Flexbox>
                                </Flexbox>
                            ) : null}
                            {this.state.optionVisible2 ? (
                                <Flexbox flexDirection="row" minWidth="100vw">
                                    <Flexbox flexGrow={1} justifyContent="center" alignItems="center">
                                        <Form inline>
                                            <Button type="button" onClick={() => this.setSaleType(2)} style={{ width: 120, height: 30, color: '#0635c9', backgroundColor: this.state.butnStatus2 ? '#ffffcc' : 'transparent', fontSize: 12, marginRight: 20 }}>خرید اقساطی</Button>
                                            <Button type="button" onClick={() => this.setSaleType(1)} style={{ width: 120, height: 30, color: '#0635c9', backgroundColor: this.state.butnStatus1 ? '#ffffcc' : 'transparent', fontSize: 12, marginLeft: 20 }}>خرید نقدی</Button>
                                        </Form>
                                    </Flexbox>
                                </Flexbox>
                            ) : null}
                            <Flexbox flexDirection="row" height="50px" minWidth="100vw">
                                <Flexbox flexGrow={1} justifyContent="center">
                                    <Button onClick={this.checkProfileAndSend} style={{ width: 120, height: 40, backgroundColor: '#0635c9', fontSize: 14, margin: 5 }}>درخواست خرید</Button>
                                </Flexbox>
                            </Flexbox>
                        </Flexbox>
                    </div>
                ) : null}

                {this.state.sellerVisible ? (
                    <div style={{ position: 'absolute', bottom: 0, width: '100%', height: '${this.state.discountHeight}', border: '1px solid #888', backgroundColor: '#fff' }}>
                        <Flexbox flexDirection="column">
                            {this.state.showCtlogu ? (
                                <Flexbox flexDirection="row" height="40px" minWidth="100vw">
                                    <Flexbox flexGrow={1} justifyContent="center" alignItems="flex-end">
                                        <Button onClick={() => this.handleSelectPage(8)} style={{ width: '100%', height: 30, backgroundColor: '#FFBB30', fontSize: 14, margin: 2, bottom: 0 }}>فهرست کالا و خدمات فروشنده</Button>
                                    </Flexbox>
                                </Flexbox>
                            ) : null}
                            <Flexbox flexDirection="row" height="60px" minWidth="100vw">
                                <Flexbox flexGrow={1}>
                                    <div onClick={this.openListNote.bind(this)} style={{ paddingLeft: 10, paddingTop: 10 }}>
                                        <img src={notepen} width="50" height="50" alt="" />
                                    </div>
                                </Flexbox>
                                <Flexbox flexGrow={2} justifyContent="center" alignItems="center">
                                    <div>
                                        <div style={{ textAlign: 'center' }}>{this.state.acceptorData.family}</div>
                                        <div style={{ textAlign: 'center' }}>{this.state.acceptorData.shop}{' - '}{this.state.acceptorData.region}</div>
                                    </div>
                                </Flexbox>
                                <Flexbox flexGrow={1} justifyContent="flex-end">
                                    {this.state.sellerBase64 ? <img className="sellerIcon" alt="" src={`data:image/png;base64,${this.state.sellerBase64}`} /> :
                                        <img src={sellerpic} className="sellerIcon" alt="logo" />}
                                </Flexbox>
                            </Flexbox>
                            <Flexbox flexDirection="row" height="40px" minWidth="100vw">
                                <Flexbox flexGrow={1} justifyContent="center" alignItems="flex-end">
                                    <Button onClick={() => this.handleSelectPage(20)} style={{ width: '100%', height: 30, backgroundColor: '#0635c9', fontSize: 14, margin: 2, bottom: 0 }}>افزایش اعتبار</Button>
                                    <a href={this.state.sellerMobile}>
                                        <div className="btnCall">
                                            <span style={{ color: '#fff' }}>تماس با فروشنده</span>
                                        </div></a>
                                </Flexbox>
                            </Flexbox>
                        </Flexbox>
                    </div>
                ) : null}

                {/* profileModal */}
                <Modal
                    isOpen={this.state.profileModalVisible}
                    style={customStyles}>
                    <Container>
                        <Row>
                            <Col style={{ alignSelf: 'center', textAlign: 'center', direction: 'rtl' }}>{this.state.modalTitle}</Col>
                        </Row>
                        <Row>
                            <Col style={{ alignSelf: 'center', textAlign: 'center', direction: 'rtl' }}>{this.state.modalSubTitle}</Col>
                        </Row>
                        <Row style={{ paddingTop: 20 }}>
                            <Col xs="6" style={{ textAlign: 'center' }}><Button onClick={this.closeModal.bind(this)} style={{ backgroundColor: 'gray' }}>خیر</Button></Col>
                            <Col xs="6" style={{ textAlign: 'center' }}><Button onClick={this.handleModalResponse.bind(this)} style={{ backgroundColor: '#0635c9' }}>بله</Button></Col>
                        </Row>
                    </Container>
                </Modal>

                {/* noteModal */}
                <Modal
                    isOpen={this.state.noteModalVisible}
                    style={customStyles}>
                    <Form>
                        <Container>
                            <Row style={{ backgroundColor: '#0635c9', height: 30, color: '#fff' }}>
                                <Col xs={10} style={{ alignSelf: 'center', textAlign: 'center', direction: 'rtl' }}>
                                    ارسال لیست خرید
                                </Col>
                                <Col xs={2} style={{ alignSelf: 'right', textAlign: 'right', direction: 'rtl' }}>
                                    <Button onClick={this.closeListNote.bind(this)} style={{ backgroundColor: '#efefef', color: '#808080' }}>X</Button>
                                </Col>
                            </Row>
                            <Row>
                                <Col style={{ alignSelf: 'center', textAlign: 'right', direction: 'rtl', paddingTop: 15 }}>
                                    <FormGroup>
                                        <Label>لیست خرید:</Label>
                                        <Input type="textarea" name="txtList" id="txtList" onChange={this.handleInputChange} value={this.state.txtList} style={{ height: 180 }} />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col style={{ alignSelf: 'center', textAlign: 'right', direction: 'rtl' }}>
                                    <FormGroup>
                                        <Label>آدرس:</Label>
                                        <Input type="textarea" name="txtAddress" id="txtAddress" onChange={this.handleInputChange} value={this.state.txtAddress} />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row style={{ paddingTop: 20 }}>
                                <Col style={{ textAlign: 'center' }}><Button onClick={this.handleSendNote.bind(this)} style={{ backgroundColor: '#0635c9' }}>ارسال به فروشنده</Button></Col>
                            </Row>
                        </Container>
                    </Form>
                </Modal>

                <MobileView>
                    <Drawer
                        visible={this.state.panelVisible}
                        onClose={this.closeDrawer}
                        placement='bottom'
                        height='90%'
                        mask={false}
                        style={{ backgroundColor: 'transparent' }}>
                        <div style={{ width: isMobile ? '100%' : 400, margin: '0 auto' }}>
                            <div className="panelBlank"></div>
                            <div className="panelContainer1">
                                <div className="buttonStyle2" onClick={() => this.setProdType(3)}>
                                    <img src={require('../../assets/svg/ic_service.svg')} alt="" style={{ width: 80, height: 70 }} />
                                    <span style={{ textAlign: 'center', color: '#000', fontFamily: 'sans', fontSize: 12 }}>خرید خدمات</span>
                                </div>
                                <div className="buttonStyle2" onClick={() => this.setProdType(2)}>
                                    <img src={require('../../assets/svg/ic_good.svg')} className="srvcIcon" alt="" />
                                    <span style={{ textAlign: 'center', color: '#000', fontFamily: 'sans', fontSize: 12 }}>خرید کالا</span>
                                </div>
                                <div className="buttonStyle2" onClick={() => this.setProdType(1)}>
                                    <img src={require('../../assets/svg/ic_cart.svg')} className="srvcIcon" alt="" />
                                    <span style={{ textAlign: 'center', color: '#000', fontFamily: 'sans', fontSize: 12 }}>خرید روزانه</span>
                                </div>
                            </div>

                            <div style={{ textAlign: 'right', direction: 'rtl', padding: 10, backgroundColor: '#fff' }}>
                                <span style={{ color: '#000', fontFamily: 'sans-bold', padding: 8, fontSize: 14 }}>سرویس های کالابیسیم!</span>
                            </div>

                            <div className="panelContainer2">
                                <div className="buttonStyle" onClick={() => this.handleSelectPage(11)}>
                                    <img src={require('../../assets/svg/saleimage.svg')} className="srvcIcon" alt="" />
                                    <span style={{ textAlign: 'center', color: '#000', fontFamily: 'sans', fontSize: 12 }}>بازاریابی و فروش</span>
                                </div>
                                <div className="buttonStyle" onClick={() => this.handleSelectPage(12)}>
                                    <img src={require('../../assets/svg/ic_bussines.svg')} className="srvcIcon" alt="" />
                                    <span style={{ textAlign: 'center', color: '#000', fontFamily: 'sans', fontSize: 12 }}>مشاوره کسب و کار</span>
                                </div>
                                <div className="buttonStyle" onClick={() => this.handleSelectPage(13)}>
                                    <img src={require('../../assets/svg/ic_bazar.svg')} className="srvcIcon" alt="" />
                                    <span style={{ textAlign: 'center', color: '#000', fontFamily: 'sans', fontSize: 12 }}>ورود به بازار</span>
                                </div>
                            </div>

                            <div className="panelContainer2">
                                <div className="buttonStyle" onClick={() => this.handleSelectPage(14)}>
                                    <img src={require('../../assets/svg/itinfo.svg')} className="srvcIcon" alt="" />
                                    <span style={{ textAlign: 'center', color: '#000', fontFamily: 'sans', fontSize: 12 }}>فناوری و ایزو</span>
                                </div>
                                <div className="buttonStyle" onClick={() => this.handleSelectPage(15)}>
                                    <img src={require('../../assets/svg/taximage.svg')} className="srvcIcon" alt="" />
                                    <span style={{ textAlign: 'center', color: '#000', fontFamily: 'sans', fontSize: 12 }}>مالی و مالیاتی</span>
                                </div>
                                <div className="buttonStyle" onClick={() => this.handleSelectPage(16)}>
                                    <img src={require('../../assets/svg/icnlaw.svg')} className="srvcIcon" alt="" />
                                    <span style={{ textAlign: 'center', color: '#000', fontFamily: 'sans', fontSize: 12 }}>حقوقی و قضایی</span>
                                </div>
                            </div>
                            <div className="panelContainer2">
                                <div className="buttonBlank">
                                </div>
                                <div className="buttonStyle2" onClick={() => this.handleSelectPage(10)}>
                                    <img src={require('../../assets/svg/ic_coprtve.svg')} className="srvcIcon" alt="" />
                                    <span style={{ textAlign: 'center', color: '#000', fontFamily: 'sans', fontSize: 12 }}>درخواست همکاری</span>
                                </div>
                                <div className="buttonStyle" onClick={() => this.handleSelectPage(17)}>
                                    <img src={require('../../assets/svg/ic_hr.svg')} alt="" className="srvcIcon" />
                                    <span style={{ textAlign: 'center', color: '#000', fontFamily: 'sans', fontSize: 12 }}>منابع انسانی</span>
                                </div>
                            </div>
                            <div style={{ backgroundColor: '#fff' }}> <Banner parentMethod={(data) => this.handleSelectPage(data)} /></div>
                        </div>
                    </Drawer>
                </MobileView>

                <BrowserView>
                    <Drawer
                        visible={this.state.panelVisible}
                        onClose={this.closeDrawer}
                        placement='bottom'
                        height='94%'
                        mask={false}
                        style={{ backgroundColor: 'transparent' }}>
                        <div style={{ position: 'absolute', bottom: 0, left: 0, width: '99%', margin: '0 auto' }}>
                            <div className="panelBlank"></div>
                            <div className="panelContainer1">
                                <Flexbox flexDirection="row">
                                    <Flexbox flexGrow={1} style={{ padding: 5, backgroundColor: '#fff', borderRadius: 10 }}>
                                        <div className="buttonStyle2" onClick={() => this.setProdType(3)}>
                                            <img src={require('../../assets/svg/ic_service.svg')} alt="" style={{ width: 80, height: 70 }} />
                                            <span style={{ textAlign: 'center', color: '#000', fontFamily: 'sans', fontSize: 12 }}>خرید خدمات</span>
                                        </div>
                                        <div className="buttonStyle2" onClick={() => this.setProdType(2)}>
                                            <img src={require('../../assets/svg/ic_good.svg')} alt="" className="srvcIcon" />
                                            <span style={{ textAlign: 'center', color: '#000', fontFamily: 'sans', fontSize: 12 }}>خرید کالا</span>
                                        </div>
                                        <div className="buttonStyle2" onClick={() => this.setProdType(1)}>
                                            <img src={require('../../assets/svg/ic_cart.svg')} alt="" className="srvcIcon" />
                                            <span style={{ textAlign: 'center', color: '#000', fontFamily: 'sans', fontSize: 12 }}>خرید روزانه</span>
                                        </div>
                                    </Flexbox>
                                </Flexbox>
                            </div>

                            <div style={{ textAlign: 'center', direction: 'rtl' }}>
                                <span style={{ color: '#000', fontFamily: 'sans-bold', padding: 5, margin: 10, fontSize: 14, backgroundColor: '#fff' }}>سرویس های کالابیسیم!</span>
                            </div>

                            <div className="panelContainer22">
                                <Flexbox flexDirection="row">
                                    <Flexbox flexGrow={1} style={{ padding: 5, backgroundColor: '#fff', borderRadius: 10 }}>
                                        <div className="buttonStyle2" onClick={() => this.handleSelectPage(10)}>
                                            <img src={require('../../assets/svg/ic_coprtve.svg')} className="srvcIcon" alt="" />
                                            <span style={{ textAlign: 'center', color: '#000', fontFamily: 'sans', fontSize: 12 }}>درخواست همکاری</span>
                                        </div>
                                        <div className="buttonStyle2" onClick={() => this.handleSelectPage(11)}>
                                            <img src={require('../../assets/svg/saleimage.svg')} className="srvcIcon" alt="" />
                                            <span style={{ textAlign: 'center', color: '#000', fontFamily: 'sans', fontSize: 12 }}>بازاریابی و فروش</span>
                                        </div>
                                        <div className="buttonStyle2" onClick={() => this.handleSelectPage(12)}>
                                            <img src={require('../../assets/svg/ic_bussines.svg')} className="srvcIcon" alt="" />
                                            <span style={{ textAlign: 'center', color: '#000', fontFamily: 'sans', fontSize: 12 }}>مشاوره کسب و کار</span>
                                        </div>
                                        <div className="buttonStyle2" onClick={() => this.handleSelectPage(13)}>
                                            <img src={require('../../assets/svg/ic_bazar.svg')} className="srvcIcon" alt="" />
                                            <span style={{ textAlign: 'center', color: '#000', fontFamily: 'sans', fontSize: 12 }}>ورود به بازار</span>
                                        </div>
                                        <div className="buttonStyle2" onClick={() => this.handleSelectPage(14)}>
                                            <img src={require('../../assets/svg/itinfo.svg')} className="srvcIcon" alt="" />
                                            <span style={{ textAlign: 'center', color: '#000', fontFamily: 'sans', fontSize: 12 }}>فناوری و ایزو</span>
                                        </div>
                                        <div className="buttonStyle2" onClick={() => this.handleSelectPage(15)}>
                                            <img src={require('../../assets/svg/taximage.svg')} className="srvcIcon" alt="" />
                                            <span style={{ textAlign: 'center', color: '#000', fontFamily: 'sans', fontSize: 12 }}>مالی و مالیاتی</span>
                                        </div>
                                        <div className="buttonStyle2" onClick={() => this.handleSelectPage(16)}>
                                            <img src={require('../../assets/svg/icnlaw.svg')} className="srvcIcon" alt="" />
                                            <span style={{ textAlign: 'center', color: '#000', fontFamily: 'sans', fontSize: 12 }}>حقوقی و قضایی</span>
                                        </div>
                                        <div className="buttonStyle2" onClick={() => this.handleSelectPage(17)}>
                                            <img src={require('../../assets/svg/ic_hr.svg')} className="srvcIcon" alt="" />
                                            <span style={{ textAlign: 'center', color: '#000', fontFamily: 'sans', fontSize: 12 }}>منابع انسانی</span>
                                        </div>
                                    </Flexbox>
                                </Flexbox>
                            </div>
                            <div className="panelContainer2">
                                <Flexbox flexDirection="row" minWidth="100vw">
                                    <Flexbox flexGrow={1} style={{ padding: 5, backgroundColor: '#fff' }}>
                                        <Banner parentMethod={(data) => this.handleSelectPage(data)} />
                                    </Flexbox>
                                </Flexbox>
                            </div>
                            {/* <div className="wfooter">
                                <Flexbox flexDirection="row" minWidth="100vw" minHeight="4vh">
                                    <Flexbox flexGrow={1} style={{ padding: 5, backgroundColor: '#fff', width: '100%' }}>
                                        footr
                                    </Flexbox>
                                </Flexbox>
                            </div> */}
                        </div>
                    </Drawer>
                </BrowserView>

                {this.state.headerVisible ? (
                    <div className="mainHeader">
                        <Flexbox flexDirection="row" height="50px" minWidth="100vw">
                            <Flexbox flexGrow={1}>
                                {this.state.panelStatus === 0 ?
                                    <img src={kbclogo} alt="logo" width="48" height="48" style={{ marginLeft: 5 }} /> :
                                    <div style={{ padding: 10, textAlign: 'left' }}
                                        onClick={this.handlePanelStatus.bind(this)}>
                                        <Icon icon="arrow-left2" size={20} color="#000000" />
                                    </div>}
                            </Flexbox>
                            <Flexbox flexGrow={2} justifyContent="center" alignItems="center">
                                <span style={{ verticalAlign: 'middle', fontSize: 22, fontWeight: 'bold' }}>{this.state.topTitle}</span>
                            </Flexbox>
                            <Flexbox flexGrow={1} justifyContent="flex-end" alignItems="center" alignContent="flex-start">
                                <div onClick={this.toggle.bind(this)} style={{ paddingRight: 20 }}><FontAwesomeIcon icon={faBars} color='black' size={40} /></div>
                            </Flexbox>
                        </Flexbox>
                    </div>
                ) : null}

                <DrawerMenu isOpen={this.state.menuIsOpen} closeCallback={this.closeMenu.bind(this)}>
                    <div className="my-menu-content">
                        <div>
                            <Flexbox flexDirection="column" minHeight="100vh">
                                <Flexbox height="150px" justifyContent="center" alignItems="center" style={{ backgroundColor: '#0635c9' }}>
                                    <div style={{ paddingTop: 20 }}>
                                        {this.state.avatarBase64 ? <img className="userAvatar" alt="logo" src={`data:image/png;base64,${this.state.avatarBase64}`} /> :
                                            <img className="userAvatar" src={defProfile} alt="user profile" />}
                                    </div>
                                    <div style={{ position: 'absolute', top: 10, left: 10, width: 30, height: 30, padding: 10 }}
                                        onClick={this.closeMenu.bind(this)}>
                                        <Icon icon="arrow-right2" size={20} color="#c7c7c7" />
                                    </div>
                                </Flexbox>
                                <Flexbox height="50px" justifyContent="center" alignItems="center" style={{ backgroundColor: '#0635c9' }}>
                                    <div style={{ width: '100%', textAlign: 'center', color: 'white' }}>{this.state.namefamily}</div><br />
                                </Flexbox>

                                <Flexbox flexDirection="column" height="60px" justifyContent="center" alignItems="center" style={{ backgroundColor: '#fff' }}>
                                    <div style={{ padding: 10 }}><span style={{ verticalAlign: 'middle', fontSize: 14 }}>اعتبار: {this.state.userCredit} ریال</span></div>
                                    <div className="addCredit" onClick={() => this.handleSelectPage(20)}>
                                        <span style={{ marginRight: 5 }}>افزایش اعتبار</span>
                                        <Icon icon={'plus-circle'} size={12} color="#fff" />
                                    </div>
                                </Flexbox>

                                <Flexbox flexGrow={1} flexDirection="column">
                                    {this.renderChannelButtons()}
                                </Flexbox>
                                <Flexbox flexGrow={0.5} element="footer" height="40px">
                                    <div style={{ width: '100%', textAlign: 'center', color: 'gray' }}>نسخه 1.0.2</div>
                                </Flexbox>
                            </Flexbox>
                        </div>
                    </div>
                </DrawerMenu>

                {/* //other pages */}
                <MobileView>
                    <Drawer
                        visible={this.state.pageVisible}
                        onClose={this.closePage.bind(this)}
                        placement={'left'}
                        width={{ width: '100%' }}
                        height='100%'
                        mask={false}
                        zIndex="1400"
                        style={{ width: '100%', backgroundColor: 'white' }}>
                        <div>
                            <Flexbox flexDirection="column" minHeight="100vh" minWidth={'100vw'}>
                                <Flexbox className="sticky">
                                    <Flexbox className="pageHeader" flexDirection="row" height="50px">
                                        <Flexbox flexGrow={1}>
                                            <div style={{ padding: 16, textAlign: 'left' }}
                                                onClick={this.closePage.bind(this)}>
                                                <Icon icon="arrow-left2" size={20} color="#c7c7c7" />
                                            </div>
                                        </Flexbox>
                                        <Flexbox flexGrow={2} justifyContent="center" alignItems="center">
                                            <span style={{ verticalAlign: 'middle', fontSize: 18, color: 'white' }}>{this.pageTitle}</span>
                                        </Flexbox>
                                        <Flexbox flexGrow={1}>
                                            <div></div>
                                        </Flexbox>
                                    </Flexbox>
                                </Flexbox>
                                <Flexbox flexGrow={1}>
                                    <div>
                                        {this.selectPage}
                                    </div>
                                </Flexbox>
                            </Flexbox>
                        </div>
                    </Drawer>
                </MobileView>
                <BrowserView>
                    <Drawer
                        visible={this.state.pageVisible}
                        onClose={this.closePage.bind(this)}
                        placement={'right'}
                        width={{ width: '400px' }}
                        height='100%'
                        mask={false}
                        zIndex="1400"
                        style={{ width: '400px', backgroundColor: 'white' }}>
                        <div>
                            <Flexbox flexDirection="column" minHeight="100vh" minWidth={'10vw'}>
                                <Flexbox className="sticky">
                                    <Flexbox className="pageHeader" flexDirection="row" height="50px">
                                        <Flexbox flexGrow={1}>
                                            <div style={{ padding: 16, textAlign: 'left' }}
                                                onClick={this.closePage.bind(this)}>
                                                <Icon icon="arrow-left2" size={20} color="#c7c7c7" />
                                            </div>
                                        </Flexbox>
                                        <Flexbox flexGrow={2} justifyContent="center" alignItems="center">
                                            <span style={{ verticalAlign: 'middle', fontSize: 18, color: 'white' }}>{this.pageTitle}</span>
                                        </Flexbox>
                                        <Flexbox flexGrow={1}>
                                            <div></div>
                                        </Flexbox>
                                    </Flexbox>
                                </Flexbox>
                                <Flexbox flexGrow={1}>
                                    {this.selectPage}
                                </Flexbox>
                            </Flexbox>
                        </div>
                    </Drawer>
                </BrowserView>

                <Drawer
                    visible={this.state.feedbackVisible}
                    onClose={this.closeFeedbackModal.bind(this)}
                    placement={'right'}
                    width='100%'
                    height='100%'
                    mask={false}
                    zIndex="1400"
                    style={{ width: '100%', backgroundColor: 'white' }}>
                    <div>
                        <Flexbox flexDirection="column" minHeight="100vh" minWidth="100vw">
                            <Flexbox flexGrow={1}>
                                {this.feedBackPage}
                            </Flexbox>
                        </Flexbox>
                    </div>
                </Drawer>

                {/* //show request for web */}
                {/* <BrowserView> */}
                <Modal
                    isOpen={this.state.rqustVisible}
                    style={rqustStyles}>
                    <Container>
                        <Row>
                            <div style={{ width: 450, margin: '0 auto' }}>
                                <Flexbox flexDirection="column" minHeight="100vh">
                                    <Flexbox flexGrow={1} justifyContent="center">
                                        <div style={{ width: '100%', height: 50, backgroundColor: '#0635c9', color: '#fff', paddingTop: 15, textAlign: 'center' }}>
                                            جستجوی ارائه کنندگان کالا و خدمات</div>
                                    </Flexbox>

                                    <Flexbox flexGrow={1} justifyContent="center" alignItems="center">
                                        {this.state.sellerBase64 ? <img className="sellerImage" alt="logo" src={`data:image/png;base64,${this.state.sellerBase64}`} /> :
                                            <img src={sellerpic} className="sellerImage" alt="logo" width="110" height="110" style={{ margin: 20 }} />}
                                    </Flexbox>

                                    <Flexbox flexGrow={1} justifyContent="center" alignItems="center">
                                        {!this.state.sellerFound ? (
                                            <div>
                                                <img src={waitpic} alt="pic" width="160" height="125" style={{ margin: 20 }} /><br />
                                                <div style={{ padding: 10, textAlign: 'right', direction: 'rtl' }}>در حال جستجوی صاحبان کالا و خدمات ...</div>
                                            </div>
                                        ) : (
                                                <div>
                                                    <div style={{ textAlign: 'center' }}>{this.state.acceptorData.shop}</div>
                                                    <div style={{ textAlign: 'center' }}>{this.state.acceptorData.family}</div>
                                                    <div style={{ textAlign: 'center' }}>{this.state.acceptorData.region}</div>
                                                    <div style={{ textAlign: 'center' }}>{this.state.acceptorData.mobile}</div>
                                                    <br /><br />

                                                    <Flexbox flexGrow={1} justifyContent="center">
                                                        <a href={this.state.sellerMobile}>
                                                            <div className="btnCallGray">
                                                                <span style={{ color: '#000' }}>تماس با فروشنده</span>
                                                            </div></a>
                                                    </Flexbox>
                                                    <Flexbox flexGrow={1} justifyContent="center">
                                                        <Button onClick={this.handleRealized.bind(this)} style={{ width: 120, height: 40, backgroundColor: '#0635c9', direction: 'rtl' }}>متوجه شدم!</Button>
                                                    </Flexbox>
                                                </div>
                                            )}
                                    </Flexbox>

                                    <Flexbox flexGrow={1} justifyContent="center">
                                        <Button onClick={this.handleCancel.bind(this)} style={{ width: 120, height: 30, backgroundColor: '#F0AA47', fontSize: 12, margin: 20 }}>لغو درخواست</Button>
                                    </Flexbox>
                                </Flexbox>

                                {/* cancelModal */}
                                <Modal
                                    isOpen={this.state.cancelModalVisible}
                                    style={customStyles}>
                                    <Container>
                                        <Row>
                                            <Col style={{ alignSelf: 'center', textAlign: 'center', direction: 'rtl' }}>{this.state.modalTitle}</Col>
                                        </Row>
                                        <Row>
                                            <Col style={{ alignSelf: 'center', textAlign: 'center', direction: 'rtl' }}>{this.state.modalSubTitle}</Col>
                                        </Row>
                                        <Row style={{ paddingTop: 20 }}>
                                            <Col xs="6" style={{ textAlign: 'center' }}><Button onClick={this.closeCancelModal.bind(this)} style={{ backgroundColor: 'gray' }}>خیر</Button></Col>
                                            <Col xs="6" style={{ textAlign: 'center' }}><Button onClick={this.handleDoCancel.bind(this)} style={{ backgroundColor: '#0635c9' }}>بله</Button></Col>
                                        </Row>
                                    </Container>
                                </Modal>
                            </div>
                        </Row>
                    </Container>
                </Modal>
                {/* </BrowserView> */}

                {this.state.creditVisible ? (
                    <div className="btnCredit" onClick={() => this.handleSelectPage(20)}>
                        <span style={{ marginRight: 5 }}>افزایش اعتبار</span>
                        <Icon icon={'plus-circle'} size={12} color="#fff" />
                    </div>
                ) : null}
                {this.state.myreqVisible ? (
                    <div className="btnMyreq" onClick={() => this.handleSelectPage(21)}>
                        <span style={{ marginRight: 5, direction: 'rtl' }}>درخواست ها ({this.state.requestCount})</span>
                        <Icon icon={'plus-circle'} size={12} color="#fff" />
                    </div>
                ) : null}
            </div>
        );
    }
}

export default Home;