import React from 'react';
import Axios from 'axios';
import { Button, Form, Input } from 'reactstrap';
import Flexbox from 'flexbox-react';
import invitepic from '../../assets/images/invitepic.png';
import { isMobile } from "react-device-detect";
import { ToastsContainer, ToastsStore, ToastsContainerPosition } from 'react-toasts';
import API_URL from '../Api/ApiUrl';
// import './Invite.css';

class Invite extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            user_id: '0',
            notify: '',
            invite_code: '',
            input_code: '',

        }

        this.handleInputChange = this.handleInputChange.bind(this);
        this.recordInvite = this.recordInvite.bind(this);
    }

    componentDidMount() {
        const _value1 = window.localStorage.getItem('_userId');
        const _value2 = window.localStorage.getItem('_inviteCode');
        this.setState({ user_id: _value1 });
        this.setState({ invite_code: _value2 });
    }

    handleInputChange(eventData) {
        const target = eventData.target;
        const value = target.value;
        const name = target.name;
        this.setState({ [name]: value })
    }

    recordInvite = () => {
        let userId = this.state.user_id;
        let inviteCode = this.state.input_code.toUpperCase();
        var mssge = '';

        if (inviteCode.length == 8) {
            const invite = {
                'receiver_id': userId,
                'invite_code': inviteCode
            }
            console.log(invite);

            Axios({
                url: API_URL + 'recordInvite',
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                data: invite,
            })
                .then(function (response) {
                    console.log(response.data.message);
                    mssge = response.data.message;
                    if (mssge === 'notFound') {
                        ToastsStore.warning('کد دعوت نادرست است');
                    }
                    if (mssge === 'double') {
                        ToastsStore.warning('این کد قبلا ثبت شده است');
                    }
                    if (mssge === 'ok') {
                        ToastsStore.success('ثبت شد');
                    }
                })
                .catch(function (err) {
                    console.log(err);
                });
        } else {
            ToastsStore.warning('کد دعوت نادرست است');
        }
    }

    render() {
        var msgeText = "https://kalabisim.com";
        msgeText += "\n کد من در اپلیکیشن کالا بیسیم: \n" + this.state.invite_code;

        return (
            <div style={{ width: isMobile ? '100%' : 450 }}>
                <Flexbox flexDirection="column" minHeight="100vh" minWidth={isMobile ? '100vw' : '10vw'}>
                    <Flexbox flexGrow={1} flexDirection="column" justifyContent="center" alignItems="center">
                        <br />
                        <img src={invitepic} alt="logo" width="110" height="110" />
                        <br />
                        <div style={{ padding: 10, textAlign: 'right', direction: 'rtl' }}>{this.state.invite_code}</div>
                        <Flexbox flexGrow={0.5} justifyContent="center" alignItems="center">
                            <div style={{ textAlign: 'center', direction: 'rtl' }}>با دعوت از دوستانتان امتیاز دریافت کنید.</div>
                        </Flexbox>
                        <div style={{ padding: 20 }}>
                            <a href={`whatsapp://send?text=${msgeText}`} data-action="share/whatsapp/share" target="_blank" className="btn btn-primary"> اشتراک گذاری </a>
                        </div>
                        <div style={{ width: '96%', height: 1, backgroundColor: '#ccc' }}></div>
                    </Flexbox>

                    <Flexbox flexGrow={0.5} justifyContent="center" alignItems="center">
                        <div style={{ textAlign: 'center', direction: 'rtl' }}>کد دعوتی که از دوستان خود دریافت کردین اینجا وارد نمایید:</div>
                    </Flexbox>

                    <Flexbox flexGrow={0.5} justifyContent="center" alignItems="center">
                        <Form>
                            <Input type="text" name="input_code" id="input_code" onChange={this.handleInputChange} maxLength="8" style={{ width: 150, margin: 10 }} />
                        </Form>
                    </Flexbox>

                    <Flexbox flexGrow={2} justifyContent="center">
                        <Button onClick={this.recordInvite} style={{ width: 110, height: 35, backgroundColor: '#0635c9' }}>تأیید</Button>
                        <ToastsContainer store={ToastsStore} position={ToastsContainerPosition.BOTTOM_CENTER} />
                    </Flexbox>
                </Flexbox>
            </div>
        );
    }
}
export default Invite;