import React from 'react';
import Axios from 'axios';
import API_URL from '../Api/ApiUrl';
import Flexbox from 'flexbox-react';
import { Button, Form, FormGroup, Label, Input, FormText, FormFeedback } from 'reactstrap';
import { ToastsContainer, ToastsStore, ToastsContainerPosition } from 'react-toasts';
import {
    BrowserView,
    MobileView,
    isMobile,
    isSafari
} from "react-device-detect";
import defProfile from '../../assets/images/profiledefault.png';
import './Profile.css';

class Profile extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            user_id: 0,
            name: '',
            family: '',
            gender: '1',
            user_code: '',
            nationalCode: '',
            phoneNumber: '',
            isMale: true,
            gender: 1,
            gndrName: 'مرد',
            avatarSource: null,
            avatarBase64: null,
            validate: {
                nameState: '',
                familyState: '',
            },
        }
        this.handleInputChange = this.handleInputChange.bind(this);
        //    this.updateProfile = this.updateProfile.bind(this);
    }

    loadUserInfo() {
        try {
            const storedValue = window.localStorage.getItem('_userId');
            const value0 = storedValue === null ? 0 : storedValue;
            if (value0 !== null) {
                this.setState({ user_id: value0 });
                this.setState({ user_code: 'C' + value0 });
            }
        } catch (error) {
            console.log(error);
        }

        try {
            const storedValue = window.localStorage.getItem('_userName');
            const value1 = storedValue === null ? '' : storedValue;
            if (value1 !== null) {
                this.setState({ name: value1 });
            }
        } catch (error) {
            console.log(error);
        }

        try {
            const storedValue = window.localStorage.getItem('_userFamily');
            const value2 = storedValue === null ? '' : storedValue;
            if (value2 !== null) {
                this.setState({ family: value2 });
            }
        } catch (error) {
            console.log(error);
        }

        try {
            const storedValue = window.localStorage.getItem('_userNationalCode');
            const value3 = storedValue === null ? '' : storedValue;
            if (value3 !== null) {
                this.setState({ nationalCode: value3 });
            }
        } catch (error) {
            console.log(error);
        }

        try {
            const storedValue = window.localStorage.getItem('_phoneNumber');
            const value4 = storedValue === null ? '' : storedValue;
            if (value4 !== null) {
                this.setState({ phoneNumber: value4 });
            }
        } catch (error) {
            console.log(error);
        }
    };

    componentDidMount() {
        this.loadUserInfo();
        Axios.get(API_URL + 'getUserAvatar/C' + window.localStorage.getItem('_userId'))
            .then(response => {
                this.setState({ avatarBase64: response.data.image });
            }).catch(error => {
                console.log(error);
            });
    }

    handleChange() {
        const { gender } = this.state;
        if (gender == 1) {
            this.setState({ gender: 2 });
            this.setState({ gndrName: 'زن' });
        }
        if (gender == 2) {
            this.setState({ gender: 1 });
            this.setState({ gndrName: 'مرد' });
        }
    }

    validateName(e) {
        const { validate } = this.state
        if (e.target.value !== '') {
            validate.nameState = 'has-success'
        } else {
            validate.nameState = 'has-danger'
        }
        this.setState({ validate })
    }

    validateFamily(e) {
        const { validate } = this.state
        if (e.target.value !== '') {
            validate.familyState = 'has-success'
        } else {
            validate.familyState = 'has-danger'
        }
        this.setState({ validate })
    }

    changeUserAvatar = (base64) => {
        Axios({
            method: 'post',
            url: API_URL + 'changeAvatar',
            timeout: 8000,  // 8 seconds timeout
            headers: { "Content-Type": "application/json" },
            data: {
                user_code: this.state.user_code,
                image: base64
            }
        })
            .then(response => {
                console.log(response.data.avatar_id);
            })
            .catch(error => console.error(error))
    }

    handleInputChange(eventData) {
        const target = eventData.target;
        const value = target.value;
        const name = target.name;
        this.setState({ [name]: value })
    }

    updateProfile = () => {
        let id = this.state.user_id;
        let name = this.state.name;
        let family = this.state.family;
        let gender = this.state.gender;
        let nationalCode = this.state.nationalCode;
        let phoneNumber = this.state.phoneNumber;

        const profile = {
            'id': parseInt(id),
            'name': name,
            'family': family,
            'gender': gender,
            'nationalCode': nationalCode,
            'phoneNumber': phoneNumber
        }

        console.log(profile);

        Axios({
            url: API_URL + 'updateProfile',
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            data: profile,
        })
            .then(function (response) {
                console.log(response);
                console.log(response.data.message);

                window.localStorage.setItem('_userName', name);
                window.localStorage.setItem('_userFamily', family);
                window.localStorage.setItem('_userGender', String(gender));
                window.localStorage.setItem('_userNationalCode', nationalCode);
                window.localStorage.setItem('_phoneNumber', phoneNumber);
                window.localStorage.setItem('_profileStatus', 'ok');

                ToastsStore.success('ثبت شد');
            })
            .catch(function (err) {
                console.log(err);
            });
    }

    render() {
        const { name, family, gender, nationalCode, phoneNumber } = this.state;
        return (
            <div style={{ width: isMobile ? '100%' : 450 }}>
                <Flexbox flexDirection="column" minWidth={isMobile ? '100vw' : '10vw'}>
                    <Flexbox flexGrow={0.7} justifyContent="center" alignItems="center">
                        <div style={{ height: 110, marginTop: 20 }}>
                            {this.state.avatarBase64 ? <img className="avatarImage" src={`data:image/png;base64,${this.state.avatarBase64}`} /> :
                                <img className="avatarImage" src={defProfile} alt="user profile" />}
                        </div>
                    </Flexbox>
                    <Flexbox flexGrow={1} justifyContent="flex-end">
                        <Flexbox flexDirection="row" style={{ width: '100%', padding: 20 }}>
                            <div className="profileForm">
                                <Form>
                                    <FormGroup>
                                        <Label>نام:</Label>
                                        <Input type="text" name="name" id="name"
                                            valid={this.state.validate.nameState === 'has-success'}
                                            invalid={this.state.validate.nameState === 'has-danger'}
                                            onChange={(e) => {
                                                this.validateName(e)
                                                this.handleInputChange(e)
                                            }}
                                            value={name} />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label>نام خانوادگی:</Label>
                                        <Input type="text" name="family" id="family" 
                                            valid={this.state.validate.familyState === 'has-success'}
                                            invalid={this.state.validate.familyState === 'has-danger'}
                                            onChange={(e) => {
                                                this.validateFamily(e)
                                                this.handleInputChange(e)
                                            }}
                                        value={family} />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label>جنسیت:</Label>{'  '}
                                        <Button onClick={this.handleChange.bind(this)} type="button">{this.state.gndrName}</Button>
                                    </FormGroup>
                                    <FormGroup>
                                        <Label>منطقه محل سکونت:</Label>
                                        <Input type="text" name="nationalCode" id="nationalCode" onChange={this.handleInputChange} value={nationalCode} maxLength={12} style={{ direction: 'rtl' }} />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label>شماره تلفن ثابت:</Label>
                                        <Input type="number" name="phoneNumber" id="phoneNumber" onChange={this.handleInputChange} value={phoneNumber} maxLength={15} style={{ direction: 'ltr' }} />
                                        <span style={{ textAlign: 'center', color: '#880000', fontFamily: 'sans', fontSize: 10 }}>برای استفاده از امکان خرید روزانه، ثبت شماره تلفن ثابت الزامی است</span>
                                    </FormGroup>
                                </Form>
                                <BrowserView>
                                    <ToastsContainer store={ToastsStore} position={ToastsContainerPosition.BOTTOM_RIGHT} />
                                </BrowserView>
                                <MobileView>
                                    <ToastsContainer store={ToastsStore} position={ToastsContainerPosition.BOTTOM_CENTER} />
                                </MobileView>
                            </div>
                        </Flexbox>
                    </Flexbox>
                    <Flexbox flexGrow={1} justifyContent="center">
                        <Button onClick={this.updateProfile} style={{ width: 120, height: 40, backgroundColor: '#0635c9', fontSize: 14, marginBottom: 5 }}>تاًیید</Button>
                    </Flexbox>
                </Flexbox>
            </div>
        );
    }
}
export default Profile;