import React from 'react';
import Axios from 'axios';
import { Button } from 'reactstrap';
import Rating from '@material-ui/lab/Rating';
import Flexbox from 'flexbox-react';
import API_URL from '../Api/ApiUrl';
import './FeedBack.css';

class FeedBack extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            reqId: 0,
            purchaseInfo: '',
            feedbackList: [],
            listOption1: [],
            listOption2: [],
            isToggleButton1: true,
            isToggleButton2: false,
            isShowPanel1: true,
            isShowPanel2: false,
            onColor: '#dd5929',
            offColor: 'gray',
            rating: 0,
            fbType: 1
        }
    }

    componentDidMount() {
        var reqId = this.props.reqId;
        this.setState({ reqId: reqId });

        Axios.get(API_URL + 'getPurchaseInfo/' + reqId)
            .then(response => {
                console.log(response.data);
                this.setState({ purchaseInfo: response.data });
            }).catch(error => {
                console.log(error);
            });

        Axios.get(API_URL + 'getFeedbackList')
            .then(response => {
                console.log(response.data);
                this.setState({ feedbackList: response.data });
            }).catch(error => {
                console.log(error);
            });
    }

    setToggleButtons = (value) => {
        if (value === 1) {
            this.setState({ isToggleButton1: true });
            this.setState({ isToggleButton2: false });
            this.setState({ isShowPanel1: true });
            this.setState({ isShowPanel2: false });
        }
        if (value === 2) {
            this.setState({ isToggleButton1: false });
            this.setState({ isToggleButton2: true });
            this.setState({ isShowPanel1: false });
            this.setState({ isShowPanel2: true });
        }
    }

    handleSelectGrn = (value) => {
        const { listOption1 } = this.state;
        if (listOption1[value] == 0) {
            listOption1[value] = 1
            this.setState({ listOption1 })
        } else {
            listOption1[value] = 0
            this.setState({ listOption1 })
        }
        this.setState({ fbType: 1 })
    }

    handleSelectRed = (value) => {
        const { listOption2 } = this.state;
        if (listOption2[value] == 0) {
            listOption2[value] = 1
            this.setState({ listOption2 })
        } else {
            listOption2[value] = 0
            this.setState({ listOption2 })
        }
        this.setState({ fbType: 2 })
    }

    handleRatingChange = (event) => {
        console.log("rrrrrrrrrrrrrrrrr");
        console.log(event.target.value);
        //here set your state for rating
        this.setState({ rating: event.target.value * 10 });
    }

    render() {
        var counter1 = -1;
        const { feedbackList } = this.state;
        const { listOption1 } = this.state;
        const { listOption2 } = this.state;
        const styles = {
            listToggle: {
                height: 40,
                width: '100%',
                marginTop: 15,
                flexDirection: 'row',
                justifyContent: 'space-around',
                paddingLeft: 10,
                paddingRight: 10
            },
            vewToggle: {
                flex: 1,
                width: '100%',
                backgroundColor: '#BEBEBE',
                borderBottomColor: 'gray',
                borderBottomWidth: 4,
            },
            vewToggle1: {
                flex: 1,
                width: '100%',
                backgroundColor: '#BEBEBE',
                borderBottomColor: 'green',
                borderBottomWidth: 4,
            },
            vewToggle2: {
                flex: 1,
                width: '100%',
                backgroundColor: '#BEBEBE',
                borderBottomColor: 'red',
                borderBottomWidth: 4,
                marginEnd: 10,
                marginStart: 20,
            },
            vewToggle22: {
                flex: 1,
                width: '100%',
                backgroundColor: '#BEBEBE',
                borderBottomColor: 'gray',
                borderBottomWidth: 4,
                marginEnd: 10,
                marginStart: 20,
            },
            txtToggle: {
                width: '100%',
                textAlign: 'center',
                color: 'black',
                paddingTop: 8,
                fontSize: 16
            },
            panelView: {
                display: 'none'
            },
            panelView1: {
                flex: 1,
                margin: 10,
                flexDirection: 'column',
                justifyContent: 'center',
                alignSelf: 'stretch',
                alignItems: 'center',
                backgroundColor: 'white',
                borderColor: 'green',
                borderWidth: 1,
                borderRadius: 10,
                display: 'flex'
            },
            panelView2: {
                flex: 1,
                margin: 10,
                flexDirection: 'column',
                justifyContent: 'center',
                alignSelf: 'stretch',
                alignItems: 'center',
                backgroundColor: 'white',
                borderColor: 'red',
                borderWidth: 1,
                borderRadius: 10,
                display: 'flex'
            },
            panelGreen: {
                width: '100%',
                paddingLeft: 20,
                paddingRight: 20
            },
            panelRed: {
                width: '100%',
                paddingLeft: 20,
                paddingRight: 20
            },
            option0: {
                width: '100%',
                padding: 4,
                backgroundColor: '#ededed',
                borderColor: '#878787',
                borderWidth: 1,
                borderRadius: 10,
                margin: 5,
            },
            option1: {
                width: '100%',
                padding: 4,
                backgroundColor: '#b8f1d8',
                borderColor: '#878787',
                borderWidth: 1,
                borderRadius: 10,
                margin: 5,
            },
            option2: {
                width: '100%',
                padding: 4,
                backgroundColor: '#facdcd',
                borderColor: '#878787',
                borderWidth: 1,
                borderRadius: 10,
                margin: 5
            },
            family: {
                textAlign: 'right',
                fontSize: 14,
                margin: 5,
                color: 'black',
                paddingRight: 10,
            },
            itemText: {
                textAlign: 'right',
                fontSize: 14,
                color: '#454545',
                margin: 6,
                paddingRight: 10,
            },
        };

        return (
            <div style={{ width: '100%', textAlign: 'center' }}>
                <Flexbox flexDirection="column" minHeight="100vh">
                    <Flexbox flexGrow={0.2} justifyContent="center">
                        <div style={{ width: '100%', height: 55, backgroundColor: '#0635c9', color: '#fff', paddingTop: 15, fontSize: '14pt' }}>
                            بازخورد خرید
                    </div>
                    </Flexbox>

                    <Flexbox flexGrow={0.2} justifyContent="center" alignItems="center">
                        <div style={{ width: '100%', height: 40, paddingTop: 10, lineHeight: 1.5 }}>
                            خرید از: {this.state.purchaseInfo.product}<br />
                            {this.state.purchaseInfo.shop}
                    </div>
                    </Flexbox>

                    <Flexbox flexGrow={0.2} justifyContent="center" alignItems="center">
                        <div style={{ width: '100%', height: 30, paddingTop: 10, direction: 'rtl' }}>
                            امتیاز شما به فروشنده در مورد این خرید:
                    </div>
                    </Flexbox>

                    <Flexbox flexGrow={0.3} justifyContent="center" alignItems="center" style={{ height: 20 }}>
                        <Rating name="size-large" defaultValue={1.0} precision={1} size="large" onChange={this.handleRatingChange} />
                    </Flexbox>

                    <Flexbox flexDirection="row" flexGrow={0.2} justifyContent="center" alignItems="center">
                        <Flexbox style={styles.listToggle}>
                            <Button onClick={() => { this.setToggleButtons(2) }} style={styles.vewToggle22, this.state.isToggleButton2 ? styles.vewToggle2 : styles.vewToggle22}>
                                <span style={styles.txtToggle}>نقاط ضعف</span>
                            </Button>
                        </Flexbox>
                        <Flexbox style={styles.listToggle}>
                            <Button onClick={() => { this.setToggleButtons(1) }} style={styles.vewToggle, this.state.isToggleButton1 ? styles.vewToggle1 : styles.vewToggle}>
                                <span style={styles.txtToggle}>نقاط قوت</span>
                            </Button>
                        </Flexbox>
                    </Flexbox>

                    <Flexbox flexGrow={0.1} justifyContent="center">
                        <div style={{ width: '98%', height: 1, backgroundColor: 'gray' }}></div>
                    </Flexbox>

                    <Flexbox flexGrow={2} justifyContent="center" alignItems="center">
                        {/* green */}
                        <div style={styles.panelView1, this.state.isShowPanel1 ? styles.panelView1 : styles.panelView}>
                            <div style={styles.panelGreen}>
                                {feedbackList.map((item) => {
                                    if (item.type == 1) {
                                        counter1++;
                                        return (
                                            <div key={item.id} style={styles.option0, this.state.listOption1[item.id - 1] == 1 ? styles.option1 : styles.option0}
                                                onClick={() => this.handleSelectGrn(item.id - 1)}
                                            >
                                                <div>
                                                    <span style={styles.itemText}>{item.title}</span>
                                                </div>
                                            </div>
                                        )
                                    }
                                })}
                            </div>
                        </div>

                        {/* red */}
                        <div style={styles.panelView2, this.state.isShowPanel2 ? styles.panelView2 : styles.panelView}>
                            <div style={styles.panelRed}>
                                {feedbackList.map((item) => {
                                    if (item.type == 2) {
                                        return (
                                            <div key={item.id} style={styles.option0, this.state.listOption2[item.id - 1] == 1 ? styles.option2 : styles.option0}
                                                onClick={() => this.handleSelectRed(item.id - 1)}
                                            >
                                                <div>
                                                    <span style={styles.itemText}>{item.title}</span>
                                                </div>
                                            </div>
                                        )
                                    }
                                })}
                            </div>
                        </div>
                    </Flexbox>

                    <Flexbox flexGrow={0.5} justifyContent="center">
                        <Button onClick={() => this.props.closeFeedbackModal(this.props.reqId, this.state.rating, this.state.fbType)} style={{ width: 80, height: 30, backgroundColor: '#0635c9', fontSize: 12 }}>ثبت</Button>
                    </Flexbox>
                </Flexbox>

            </div>
        );
    }
}
export default FeedBack;