import React from 'react';
import Iframe from 'react-iframe';
import { isMobile } from "react-device-detect";
import { Document } from 'react-pdf';
import { Spinner } from 'reactstrap';
import './WebView.css';

class WebView extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            page_url: '',
            page_title: 'کالابیسیم',
            iframeLoaded: false,
            isPageLoaded: false,
            isDocumentPdf: false,
        }
    }

    componentDidMount() {
        var pageId = this.props.page_id;
        if (pageId > 50 && pageId < 55)
            this.setState({ isDocumentPdf: true });
        else
            this.setState({ isDocumentPdf: false });
        switch (pageId) {
            case 0:
                this.setState({ page_url: 'https://kalabisim.com' });
                break;
            case 51:
                this.setState({ page_url: 'https://docs.google.com/viewer?url=http://api.kalabisim.com/help/customer_lottery.pdf&embedded=true' });
                // this.setState({ page_url: 'http://api.kalabisim.com/help/customer_lottery.pdf' });
                break;
            case 52:
                this.setState({ page_url: 'https://docs.google.com/viewer?url=http://api.kalabisim.com/help/customer_intro.pdf&embedded=true' });
                // this.setState({ page_url: 'http://api.kalabisim.com/help/customer_intro.pdf' });
                break;
            case 53:
                this.setState({ page_url: 'https://docs.google.com/viewer?url=http://api.kalabisim.com/help/customer_help.pdf&embedded=true' });
                // this.setState({ page_url: 'http://api.kalabisim.com/help/customer_help.pdf' });
                break;
            case 54:
                this.setState({ page_url: 'https://docs.google.com/viewer?url=http://api.kalabisim.com/help/customer_ghesti.pdf&embedded=true' });
                // this.setState({ page_url: 'http://api.kalabisim.com/help/customer_ghesti.pdf' });
                break;
            case 55:
                this.setState({ page_url: 'http://karabc.com' });
                break;
            case 56:
                this.setState({ page_url: 'https://api.kalabisim.com/banners/6' });
                break;

            case 11:
                this.setState({ page_url: 'http://karabc.com/مرکز-مشاوره-ایران/خدمات-بازاریابی-و-فروش/' });
                break;
            case 12:
                this.setState({ page_url: 'http://karabc.com/مرکز-مشاوره-ایران/مشاوره-مدیریت/' });
                break;
            case 13:
                this.setState({ page_url: 'http://karabc.com/مرکز-مشاوره-ایران/ورود-به-بازار/' });
                break;
            case 14:
                this.setState({ page_url: 'http://karabc.com/مرکز-مشاوره-ایران/خدمات-فناوری-اطلاعات-و-ایزو/' });
                break;
            case 15:
                this.setState({ page_url: 'http://karabc.com/مرکز-مشاوره-ایران/خدمات-مالی-و-مالیاتی/' });
                break;
            case 16:
                this.setState({ page_url: 'http://karabc.com/مرکز-مشاوره-ایران/خدمات-حقوقی-و-قضایی/' });
                break;
            case 17:
                this.setState({ page_url: 'http://karabc.com/مرکز-مشاوره-ایران/خدمات-منابع-انسانی/' });
                break;
            default:
                this.setState({ page_url: 'https://kalabisim.com' });
        }
    }

    handleLoadedIframe = () => {
        this.setState({ iframeLoaded: true })
        this.setState({ isPageLoaded: true })
    }

    render() {
        return (
            <div>
                {this.state.isDocumentPdf ? (
                    <div>
                        <Iframe
                            url={this.state.page_url}
                            position="absolute"
                            width="100%"
                            id="myId"
                            className="myClassname"
                            height="100%"
                            frameBorder="0"
                            scrolling="no"
                            styles={{ height: "1200px" }}
                            onLoad={this.handleLoadedIframe} />

                        {this.state.isPageLoaded ? null : (
                            <div className="pageSpinner"><Spinner color="primary" style={{ width: '3rem', height: '3rem' }} /></div>
                        )}
                    </div>
                ) : (
                        <div className="iframe-loading">
                            <Iframe
                                url={this.state.page_url}
                                position="absolute"
                                width="100%"
                                id="myId"
                                className="myClassname"
                                height="100%"
                                frameBorder="0"
                                scrolling="no"
                                styles={{ height: "1200px" }}
                                onLoad={this.handleLoadedIframe} />

                            {this.state.isPageLoaded ? null : (
                                <div className="pageSpinner"><Spinner color="primary" style={{ width: '3rem', height: '3rem' }} /></div>
                            )}
                        </div>
                    )}
            </div>
        );
    }
}
export default WebView;