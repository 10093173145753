import React from 'react';
import Flexbox from 'flexbox-react';
import { isAndroid, isMobile, isIOS, isBrowser } from "react-device-detect";
import { Button, Input, Container, Row, Col, Form, FormGroup, Table } from 'reactstrap';
import kbcLogo from '../../assets/images/logo_fa.png';
import Modal from 'react-modal';
import Icon from "../Icons/icon";

Modal.setAppElement('*');
const dwnldStyles = {
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(110, 110, 110, 0.65)'
    },
    content: {
        width: '100%',
        height: '100%',
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        padding: 10,
        overflowX: 'hidden',
        overflowY: 'hidden',
        borderRadius: 10
    }
};

class Splash extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            firstLunch: true,
            dwnldVisible: false,
            screenVisible: false,
        }
    }

    componentDidMount() {
        if (isMobile && isAndroid) {
            this.setState({ dwnldVisible: true });
        } else {
            const lunchStatus = window.localStorage.getItem('_fisrtLunch');
            if (isMobile && isIOS && lunchStatus == null) {
                this.setState({ screenVisible: true });
            } else {
                this.doStartApp();
            }
        }
    }

    async doStartApp() {
        const data = await this.performTimeConsumingTask();
        const lunchStatus = window.localStorage.getItem('_fisrtLunch');
        if (!lunchStatus != null && lunchStatus === 'false') {
            this.props.history.push({ pathname: '/home' });
        } else {
            this.props.history.push({ pathname: '/start' });
        }
        if (data !== null) {
            this.setState({ isLoading: false });
        }
    }

    performTimeConsumingTask = async () => {
        return new Promise((resolve) =>
            setTimeout(
                () => { resolve('result') },
                2000
            )
        );
    }

    closeScreenModal() {
        this.setState({ screenVisible: false });
        this.doStartApp();
    }

    render() {
        return (
            <div>
                <Flexbox flexDirection="column" minHeight="100vh">
                    <Flexbox flexGrow={1} justifyContent="center" alignItems="center">
                        <img src={kbcLogo} alt="logo" width="110" height="110" />
                    </Flexbox>
                </Flexbox>

                {/* Download View for Android */}
                <Modal
                    isOpen={this.state.dwnldVisible}
                    style={dwnldStyles}>
                    <Container>
                        <Row>
                            <Col>
                                <Flexbox flexDirection="column" minHeight="100vh">
                                    <Flexbox flexGrow={1} justifyContent="center" alignItems="center">
                                        <img src={kbcLogo} alt="logo" width="110" height="110" />
                                    </Flexbox>

                                    <Flexbox justifyContent="center">
                                        <div style={{ padding: 10, textAlign: 'center', direction: 'rtl', color: '#000088', fontSize: '16pt' }}>به کالابیسیم خوش آمدید</div>
                                    </Flexbox>
                                    <Flexbox justifyContent="center">
                                        <div style={{ padding: 10, textAlign: 'center', direction: 'rtl', color: '#333', fontSize: '14pt' }}>پلتفرم فروشنده آنلاین</div>
                                    </Flexbox>
                                    <Flexbox justifyContent="center">
                                        <div style={{ padding: 10, textAlign: 'center', direction: 'rtl', color: '#181818', fontSize: '12pt' }}>با تمرکز بر توسعه فروش محلی</div>
                                    </Flexbox>
                                    <Flexbox justifyContent="center">
                                        <div style={{ padding: 40, textAlign: 'center', direction: 'rtl', color: '#000044', fontSize: '14pt', border: '0px solid #888' }}>هدف ما بکار گیری فضای اینترنت در اشتغال زایی، درآمد زایی و بهتر دیده شدن فروشندگان محلی است</div>
                                    </Flexbox>

                                    <Flexbox justifyContent="center">
                                        <div style={{ margin: 10 }}><img src={require('../../assets/images/kalabsm.png')} alt="" style={{ width: 150, height: 50 }} /></div>
                                    </Flexbox>

                                    <Flexbox justifyContent="center">
                                        <div style={{ margin: 10 }}><img src={require('../../assets/images/caffebazar.png')} alt="" style={{ width: 150, height: 50 }} /></div>
                                    </Flexbox>
                                    <Flexbox flexGrow={1} justifyContent="center" alignItems="center">

                                    </Flexbox>

                                </Flexbox>
                            </Col>
                        </Row>
                    </Container>
                </Modal>

                {/* Screen View for iOS */}
                <Modal
                    isOpen={this.state.screenVisible}
                    style={dwnldStyles}>
                    <Container style={{ overflowY: 'auto' }}>
                        <Row>
                            <Col>
                                <Flexbox flexDirection="column" minHeight="100vh">
                                    <Flexbox flexGrow={1} justifyContent="center" alignItems="center">
                                        <img src={kbcLogo} alt="logo" width="100" height="100" />
                                    </Flexbox>

                                    <Flexbox justifyContent="center">
                                        <div style={{ padding: 10, textAlign: 'center', direction: 'rtl', color: '#000088', fontSize: '14pt' }}>به کالابیسیم خوش آمدید</div>
                                    </Flexbox>
                                    <Flexbox justifyContent="center">
                                        <div style={{ padding: 10, textAlign: 'center', direction: 'rtl', color: '#333', fontSize: '13pt', paddingRight: 20 }}>وب اپلیکیشن کالابیسیم را به صفحه اصلی موبایل خود اضافه کنید.</div>
                                    </Flexbox>

                                    <Flexbox flexGrow={1} justifyContent="center" alignItems="center" style={{ paddingRight: 20 }}>
                                        <Table>
                                            <tbody>
                                                <tr>
                                                    <td style={{ padding: 10, textAlign: 'right', direction: 'rtl', color: '#181818', fontSize: '12pt' }}>1- در نوار پایین، دکمه
                                                    {'  '}<img src={require('../../assets/images/sharebutn2.png')} alt="" style={{ width: 20, height: 25 }} />{'  '}
                                                     را انتخاب کنید.</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ padding: 10, textAlign: 'right', direction: 'rtl', color: '#181818', fontSize: '12pt' }}>2- در منوی باز شده گزینه
                                                    {'  '}<span style={{ color: '#0011ee', fontSize: '20pt' }}> + </span>{'  '}
                                                        <span style={{ color: '#888', fontSize: '12pt', fontFamily: 'Arial', fontWeight: 'bold' }}>(Add to Home Screen)</span>{'  '}
                                                      را انتخاب کنید.</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ padding: 10, textAlign: 'right', direction: 'rtl', color: '#181818', fontSize: '12pt' }}>3- در قسمت بالا روی گزینه
                                                    {'  '}<span style={{ color: '#0011ee', fontSize: '12pt', fontFamily: 'Arial', fontWeight: 'bold' }}>Add</span>{'  '}
                                                     کلیک کنید.</td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </Flexbox>

                                    <Flexbox flexGrow={0.5} justifyContent="center" alignItems="center">

                                    </Flexbox>

                                    <Flexbox justifyContent="center">
                                        <Button onClick={this.closeScreenModal.bind(this)} className="btnOk">متوجه شدم!</Button>
                                    </Flexbox>

                                    <Flexbox flexGrow={1.5} justifyContent="center" alignItems="center">

                                    </Flexbox>
                                </Flexbox>
                            </Col>
                        </Row>
                    </Container>
                </Modal>

            </div>
        );
    }
}
export default Splash;