import React from 'react';
import Axios from 'axios';
import Flexbox from 'flexbox-react';
import { isMobile } from "react-device-detect";
import API_URL from '../Api/ApiUrl';
import sellerpic from '../../assets/images/unknown.png';
import './Purchase.css';

class Purchase extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            reqId: 0,
            sellerBase64: null,
            purchaseInfo: '',
            imageSource: null,
            photo: null,
            imgInvce: null
        }
    }

    componentDidMount() {
        const reqId = this.props.reqId;
        Axios.get(API_URL + 'getPurchaseInfo/' + reqId)
            .then(response => {
                console.log(response.data);
                this.setState({ purchaseInfo: response.data });

                Axios.get(API_URL + 'getUserAvatar/S' + this.state.purchaseInfo.seller_id)
                    .then(response => {
                        this.setState({ sellerBase64: response.data.image });
                    }).catch(error => {
                        console.log(error);
                    });

                Axios({
                    url: API_URL + 'getInvoice/' + reqId,
                    method: 'GET',
                    responseType: 'blob', // important
                }).then((response) => {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    this.setState({ imgInvce: url });
                });

            }).catch(error => {
                console.log(error);
            });
    }

    render() {

        const styles = {
            family: {
                textAlign: 'right',
                fontFamily: 'sans-bold',
                fontSize: 14,
                margin: 5,
                color: 'black',
                paddingRight: 10,
            },
            itemspan: {
                textAlign: 'right',
                fontFamily: 'sans',
                fontSize: 14,
                color: '#454545',
                margin: 6,
                paddingRight: 10,
            },
            invoice: {
                width: 220,
                height: 300,
                borderWidth: 1,
                borderColor: 'gray',
                marginTop: 10,
            },
        };

        return (
            <div style={{ width: isMobile ? '100%' : 450 }}>

                <Flexbox flexDirection="column" minWidth={isMobile ? '100vw' : '10vw'}>
                    <Flexbox flexGrow={1} flexDirection="column" style={{ padding: 25 }}>
                        <div style={{ textAlign: 'center' }}>

                            {this.state.sellerBase64 ? <img className="sellerImage" alt="" src={`data:image/png;base64,${this.state.sellerBase64}`} /> :
                                <img className="sellerImage" src={require('../../assets/images/unknown.png')} />}

                            <div style={{
                                width: '98%',
                                borderBottomColor: 'gray',
                                borderBottomWidth: 1,
                                margin: 5
                            }} />
                        </div>
                        <div style={{ direction: 'rtl', textAlign: 'center' }}>
                            <span style={{ fontWeight: 'bold' }}>{this.state.purchaseInfo.shop}</span>
                        </div>
                        <div style={{ width: '98%', direction: 'rtl', textAlign: 'right' }}>
                            <span>نام فروشنده: {this.state.purchaseInfo.family}</span><br />
                            <span>شماره تماس: {this.state.purchaseInfo.mobile}</span><br />
                            <span>آدرس: {this.state.purchaseInfo.address}</span><br />
                            <span>نوع خرید: {this.state.purchaseInfo.product}</span><br />
                            <span>تاریخ خرید: {this.state.purchaseInfo.date}</span><br />
                        <span>مبلغ فاکتور: {this.state.purchaseInfo.amount}{' '}ریال</span><br /><br />
                            <span>فاکتور فروش:</span><br />
                        </div>
                        <div>
                            <div style={{ textAlign: 'center', padding: 10 }} colSpan="2">
                                {this.state.imgSrc === null ?
                                    <img style={styles.invoice} alt="user invoice"
                                        src={require('../../assets/images/invoice.jpg')} /> :
                                    <img style={styles.invoice} src={this.state.imgInvce} />
                                }
                            </div>
                        </div>
                    </Flexbox>
                </Flexbox>
            </div>
        );
    }
}
export default Purchase;