import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import About from './components/About';
import Splash from './components/Splash';
import Start from './components/Start';
import Activation from './components/Activation';
import Home from './components/Home/Home';
import Request from './components/Request';
import FeedBack from './components/FeedBack';

import './App.css';

class App extends Component {

  constructor(props) {
    super(props);
    this.state = {
      viewTitle: '',
    };
  }

  render() {
    
    return (
      <Router forceRefresh={false}>
        <div className="App">
          <div className="contentArea">
            <Switch>
              <Route exact={true} path="/" component={Splash} />
              <Route path="/start" component={Start} />
              <Route path="/activation" component={Activation} />
              <Route path="/home" component={Home} />
              <Route path="/request" component={Request} />
              <Route path="/feedback" component={FeedBack} />
             </Switch>
          </div>
        </div>
      </Router>
    );
  }
}

export default App;
