import React from 'react';
import Axios from 'axios';
import API_URL from '../Api/ApiUrl';
import Flexbox from 'flexbox-react';
import { Button, Form, FormGroup, Label, Input, Container, Row, Col } from 'reactstrap';
import { ToastsContainer, ToastsStore, ToastsContainerPosition } from 'react-toasts';
import {
    BrowserView,
    MobileView,
    isMobile,
    isSafari
} from "react-device-detect";
import {
    withScriptjs,
    withGoogleMap,
    GoogleMap,
    Marker,
} from "react-google-maps";
import mapStyles from '../Home/mapStyles';
import './Cooperate.css';

class Cooperate extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            user_id: 0,
            name: '',
            family: '',
            gender: '1',
            mobile: '',
            activity_type: 0,
            activity_title: '',
            isMale: true,
            gender: 1,
            gndrName: 'مرد',
            validate: {
                nameState: '',
                familyState: '',
                mobileState: '',
            },
            mapCenter: {
                lat: 35.70092132333425,
                lng: 51.391260847449296
            },
            currentPosition: {
                lat: 0,
                lng: 0
            },
            selectedType: 0,
        }
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    handleChange() {
        const { gender } = this.state;
        if (gender == 1) {
            this.setState({ gender: 2 });
            this.setState({ gndrName: 'زن' });
        }
        if (gender == 2) {
            this.setState({ gender: 1 });
            this.setState({ gndrName: 'مرد' });
        }
    }

    validateName(e) {
        const { validate } = this.state
        if (e.target.value !== '') {
            validate.nameState = 'has-success'
        } else {
            validate.nameState = 'has-danger'
        }
        this.setState({ validate })
    }

    validateFamily(e) {
        const { validate } = this.state
        if (e.target.value !== '') {
            validate.familyState = 'has-success'
        } else {
            validate.familyState = 'has-danger'
        }
        this.setState({ validate })
    }

    validateMobile(e) {
        const { validate } = this.state
        if (e.target.value !== '') {
            validate.mobileState = 'has-success'
        } else {
            validate.mobileState = 'has-danger'
        }
        this.setState({ validate })
    }

    handleInputChange(eventData) {
        const target = eventData.target;
        const value = target.value;
        const name = target.name;
        this.setState({ [name]: value })
    }

    handleChangeType = (event) => {
        this.setState({ selectedType: event.target.value });
        this.setState({ activity_type: event.target.value });
    };

    setMapCenter() {
        if (this.state.isSetCurrent) {
            this.setState({ mapCenter: this.state.currentPosition })
        } else {
            this.setState({ mapCenter: this.state.currentPosition })
        }
    }

    onMapClick = (event) => {
        this.setState(() => ({
            isSetCurrent: true,
            mapCenter: {
                lat: event.latLng.lat(),
                lng: event.latLng.lng()
            },
            currentPosition: {
                lat: event.latLng.lat(),
                lng: event.latLng.lng()
            }
        }));

        this.setState({ latitude: event.latLng.lat() })
        this.setState({ longitude: event.latLng.lng() })

        // console.log(event.latLng.lat());
        // console.log(event.latLng.lng());
    }

    onSubmit = (eventData) => {
        eventData.preventDefault();
        this.setState({ userId: sessionStorage.getItem('_curntUser') });
        const { validate, name, family, mobile, selectedType } = this.state;
        if (name !== '' && family !== '' && mobile !== '' && selectedType !== 0) {

            const candidate = {
                'id': 0,
                'name': this.state.name,
                'family': this.state.family,
                'gender': parseInt(this.state.gender),
                'mobile': this.state.mobile,
                'activity_type': parseInt(this.state.selectedType),
                'activity_title': this.state.activity_title,
                'latitude': this.state.latitude,
                'longitude': this.state.longitude,
            }

            console.log(candidate);

            Axios({
                url: API_URL + 'cooperate',
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                data: candidate,
            }).then((res) => {
                ToastsStore.success('درخواست شما ثبت شد');
                this.setState({ isDataSaved: true })
                this.setState({ showSuccess: true, showDanger: false });
            });
        } else {
            if (name === '')
                validate.nameState = 'has-danger'
            if (family === '')
                validate.familyState = 'has-danger'
            if (mobile === '')
                validate.mobileState = 'has-danger'

            if (selectedType === 0) {
                this.setState({ isTypeWarn: true })
            }

            this.setState({ validate })
        }
    }

    render() {

        let ref = null;
        let MapWrapped = withScriptjs(withGoogleMap(props => (
            <GoogleMap
                ref="map"
                ref={(mapRef) => ref = mapRef}
                onCenterChanged={() => {
                    if (this.state.panelStatus === 1) {
                        var center = ref.getCenter().toJSON();
                        console.log(center)
                        this.lastCenter = center;
                    }
                }}

                isMarkerShown={this.state.isMarkerShown}
                currentLocation={this.state.currentPosition}

                onClick={this.onMapClick.bind(this)}

                defaultZoom={16}
                defaultCenter={this.state.mapCenter}
                options={{
                    styles: mapStyles,
                    mapTypeControl: false,
                    streetViewControl: false,
                    zoomControl: true,
                    fullscreenControl: true,
                }}>

                <Marker
                    mapTypeControl={false}
                    position={this.state.mapCenter}
                    icon={{
                        scaledSize: new window.google.maps.Size(39, 56),
                        anchor: new window.google.maps.Point(20, 28),
                    }}
                />
            </GoogleMap>)
        ));

        return (
            <div style={{ width: isMobile ? '100%' : 450 }}>
                <Flexbox flexDirection="column" minHeight="100vh" minWidth={isMobile ? '100vw' : '10vw'}>
                    <Flexbox flexGrow={1} justifyContent="flex-end">
                        <Flexbox flexDirection="row" style={{ width: '100%', padding: 20 }}>
                            <div className="profileForm">
                                <Form>
                                    <FormGroup>
                                        <Label>نام:</Label>
                                        <Input type="text" name="name" id="name"
                                            valid={this.state.validate.nameState === 'has-success'}
                                            invalid={this.state.validate.nameState === 'has-danger'}
                                            onChange={(e) => {
                                                this.validateName(e)
                                                this.handleInputChange(e)
                                            }} />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label>نام خانوادگی:</Label>
                                        <Input type="text" name="family" id="family"
                                            valid={this.state.validate.familyState === 'has-success'}
                                            invalid={this.state.validate.familyState === 'has-danger'}
                                            onChange={(e) => {
                                                this.validateFamily(e)
                                                this.handleInputChange(e)
                                            }} />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label>جنسیت:</Label>{'  '}
                                        <Button onClick={this.handleChange.bind(this)} type="button">{this.state.gndrName}</Button>
                                    </FormGroup>
                                    <FormGroup>
                                        <Label>شماره همراه:</Label>
                                        <Input type="number" name="mobile" id="mobile"
                                            maxLength={15} style={{ direction: 'ltr' }}
                                            valid={this.state.validate.mobileState === 'has-success'}
                                            invalid={this.state.validate.mobileState === 'has-danger'}
                                            onChange={(e) => {
                                                this.validateMobile(e)
                                                this.handleInputChange(e)
                                            }} />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label>موقعیت مکانی:</Label>
                                        <Container>
                                            <Row>
                                                <Col xs={12} style={{ padding: 5 }}>
                                                    {/* map OK */}
                                                    <div style={{ height: 250 }}>
                                                        <MapWrapped
                                                            googleMapURL={'https://maps.googleapis.com/maps/api/js?key=AIzaSyCqdq1BBP9hTxYACzXwv2x7uxDRGpjDeWw&v=3.exp&libraries=geometry,drawing,places&language=fa'}
                                                            loadingElement={<div style={{ height: '100%' }} />}
                                                            containerElement={<div style={{ height: '100%' }} />}
                                                            mapElement={<div style={{ height: '100%' }} />} />
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Container>
                                    </FormGroup>
                                    <FormGroup>
                                        <Label>نوع فعالیت:</Label>{' '}
                                        <select className="drpdown" name="drpType" onChange={(e) => this.handleChangeType(e)}>
                                            <option value="0">انتخاب کنید...</option>
                                            <option value="1">مایحتاج روزانه</option>
                                            <option value="2">فروشگاهی</option>
                                            <option value="3">خدماتی</option>
                                        </select>
                                    </FormGroup>
                                    <FormGroup>
                                        <Label>عنوان فعالیت:</Label>
                                        <Input type="text" name="activity_title" id="activity_title"
                                            onChange={this.handleInputChange}
                                            maxLength={70} style={{ direction: 'rtl' }} />
                                    </FormGroup>
                                </Form>
                                <BrowserView>
                                    <ToastsContainer store={ToastsStore} position={ToastsContainerPosition.BOTTOM_RIGHT} />
                                </BrowserView>
                                <MobileView>
                                    <ToastsContainer store={ToastsStore} position={ToastsContainerPosition.BOTTOM_CENTER} />
                                </MobileView>
                            </div>
                        </Flexbox>
                    </Flexbox>
                    <Flexbox flexGrow={1} justifyContent="center">
                        <Button onClick={this.onSubmit} style={{ width: 110, height: 35, backgroundColor: '#0635c9', fontSize: 14, marginBottom: 20 }}>تاًیید</Button>
                    </Flexbox>
                </Flexbox>
            </div>
        );
    }
}
export default Cooperate;