import React from 'react';
import Axios from 'axios';
import Flexbox from 'flexbox-react';
import { isMobile } from "react-device-detect";
import API_URL from '../Api/ApiUrl';
import './History.css';

class History extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            userId: window.localStorage.getItem('_userId'),
            historyList: [],
        }
    }

    componentDidMount() {
        const { userId } = this.state;
        Axios.get(API_URL + 'getPurchaseList/' + userId)
            .then(response => {
                console.log(response.data);
                this.setState({ historyList: response.data });
            }).catch(error => {
                console.log(error);
            });
    }

    render() {
        const { historyList } = this.state;
        return (
            <div style={{ width: isMobile ? '100%' : 450 }}>
                <div className="cardBody">
                    {historyList.map((item) => {
                        return (<div onClick={() => this.props.handleSelectPage(7, item.id)} className="cardStyle" key={item.id}>
                            <div className="innerCard">
                                <div className="family">فروشنده: {item.seller}</div>
                                <div className="itemText">خرید: {item.product} ({item.type})</div>
                                <div className="itemText">تاریخ خرید: {item.date}</div>
                                <div className="itemText">زمان خرید: {item.time}</div>
                            </div></div>)
                    })}
                </div>
            </div>
        );
    }
}
export default History;