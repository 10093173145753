import React from 'react';
import Axios from 'axios';
import { Spinner, Button, Form, FormGroup, Input } from 'reactstrap';
import { ToastsContainer, ToastsStore, ToastsContainerPosition } from 'react-toasts';
import API_URL from '../Api/ApiUrl';
import Flexbox from 'flexbox-react';
import kbcLogo from '../../assets/images/logo_fa.png';
import './Activation.css';

class Activation extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
			userId: 0,
            userMobile: '',
            aCode: 0,
            showLoading: false
        }
        this.handleInputChange = this.handleInputChange.bind(this);
        this.checkActivationFunction = this.checkActivationFunction.bind(this);
    }

    handleInputChange(eventData) {
        const target = eventData.target;
        const value = target.value;
        const name = target.name;
        this.setState({ [name]: value })
    }

    recordLog = (log) => {
        console.log(log);
        Axios({
            url: API_URL + 'recordLog',
            method: 'POST',
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            data: log,
        })
            .then(function (response) {
                console.log(response);
                console.log(response.data);
            })
            .catch(function (err) {
                console.log(err);
            });
    }

    setUserDeviceType = (id) => {
        Axios.put(API_URL + 'setCustomerDeviceType/I/' + id)
            .then(response => {
                console.log(response.data.message);
            }).catch(error => {
                console.log(error);
            });
    }

    checkActivationFunction(eventData) {
        const { aCode } = this.state;
        if (aCode != null && aCode.length == 5) {
            eventData.preventDefault();
            const uMobile = this.props.location.state.user_mobile;
            this.setState({ showLoading: true })
            Axios.get(API_URL + 'checkCustomerCode/' + uMobile + '/' + aCode)
                .then(response => {
                    console.log(response.data.id);
                    this.setState({ userId: response.data.id });
                    const { userId } = this.state;
                    if (userId === 0) {
                        ToastsStore.warning('کد فعال سازی نادرست است');
                    } else {
                        //save to storage
                        let id = userId;
                        let name = response.data.name;
                        let family = response.data.family;
                        let gender = response.data.gender;
                        let nationalCode = response.data.nationalCode;
                        let phoneNumber = response.data.phone;
                        let mobile = response.data.mobile;
                        let invite = response.data.inviteCode;

                        window.localStorage.setItem('_userId', String(id));
                        window.localStorage.setItem('_userName', name);
                        window.localStorage.setItem('_userFamily', family);
                        window.localStorage.setItem('_userGender', String(gender));
                        window.localStorage.setItem('_userNationalCode', nationalCode);
                        window.localStorage.setItem('_userMobile', mobile);
                        window.localStorage.setItem('_fisrtLunch', 'false');
                        window.localStorage.setItem('_profileStatus', 'none');
                        window.localStorage.setItem('_inviteCode', invite);
                        window.localStorage.setItem('_phoneNumber', phoneNumber);

                        //record log
                        const log = new FormData()
                        log.append('event_id', '1');
                        log.append('agent', 'C');
                        log.append('customer_id', id);
                        log.append('seller_id', '0');
                        log.append('request_id', '0');
                        log.append('score', '0');
                        this.recordLog(log);

                        //set device type
                        this.setUserDeviceType(id);
                        this.props.history.push({ pathname: '/home' })
                    }
                }).catch(error => {
                    console.log(error);
                });
        }
    }

    render() {
        return (
            <div>
                <Flexbox flexDirection="column" minHeight="100vh">
                    <Flexbox flexGrow={1} justifyContent="center" alignItems="center">
                        <img src={kbcLogo} alt="logo" width="110" height="110" />
                    </Flexbox>

                    <Flexbox flexGrow={1} justifyContent="center">
                        <Form>
                            <FormGroup>
                                <div style={{ padding: 10, textAlign: 'right', direction: 'rtl' }}>کد فعال سازی را وارد نمایید:</div>
                                <Input type="number" name="aCode" id="aCode" onChange={this.handleInputChange} maxLength="5" placeholder="کد فعال سازی" style={{ textAlign: 'center' }} />
                                <div style={{ height: 10, padding: 10 }} >{this.state.showLoading ? <Spinner color="primary" /> : null}</div>
                            </FormGroup>
                        </Form>
                    </Flexbox>

                    <Flexbox flexGrow={0.8} justifyContent="center">
                        <Button onClick={this.checkActivationFunction} style={{ width: 120, height: 40, backgroundColor: '#0635c9' }}>تأیید</Button>
                    </Flexbox>
                </Flexbox>
                <ToastsContainer store={ToastsStore} position={ToastsContainerPosition.BOTTOM_CENTER} />
            </div>
        );
    }
}
export default Activation;