import React from 'react';
import Axios from 'axios';
import { Button, Form, FormGroup, Input, Spinner } from 'reactstrap';
import API_URL from '../Api/ApiUrl';
import Flexbox from 'flexbox-react';
import kbcLogo from '../../assets/images/logo_fa.png';
import './Start.css';

class Start extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            userMobile: '',
            userId: '',
            showLoading: false,
            validate: {
                userMobileState: ''
            },
        }
        this.handleInputChange = this.handleInputChange.bind(this);
        this.doRegisterFunction = this.doRegisterFunction.bind(this);
    }

    handleInputChange(eventData) {
        const target = eventData.target;
        const value = target.value;
        const name = target.name;
        this.setState({ [name]: value })
    }


    validateUserMobile(e) {
        const { validate } = this.state;
        if (e.target.value !== '') {
            validate.userMobileState = 'has-success'
        } else {
            validate.userMobileState = 'has-danger'
        }
        this.setState({ validate });
    }

    doRegisterFunction(eventData) {
        const { validate, userMobile } = this.state;
        if (userMobile != null && userMobile.length == 11) {
            eventData.preventDefault();
            this.setState({ showLoading: true })
            Axios({
                method: 'post',
                url: API_URL + 'userRegister',
                timeout: 8000,  // 8 seconds timeout
                headers: { "Content-Type": "application/json" },
                data: {
                    mobile: userMobile
                }
            })
                .then(response => {
                    console.log(userMobile);
                    //console.log(response.data.act_code);
                    this.props.history.push({ pathname: '/activation', state: { user_mobile: this.state.userMobile } })
                })
                .catch(error => console.error(error))
        } else {
            if (userMobile.length < 11 || userMobile.length > 11)
                validate.userMobileState = 'has-danger'
            this.setState({ validate });
        }
    }

    render() {
        return (
            <div>
                <Flexbox flexDirection="column" minHeight="100vh">
                    <Flexbox flexGrow={1} justifyContent="center" alignItems="center">
                        <img src={kbcLogo} alt="logo" width="110" height="110" />
                    </Flexbox>

                    <Flexbox flexGrow={1} justifyContent="center">
                        <Form>
                            <FormGroup>
                                <div style={{ padding: 10, textAlign: 'right', direction: 'rtl' }}>شماره موبایل خود را وارد نمایید:</div>
                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                    <Input type="number" name="userMobile" id="userMobile" maxLength="11"
                                        valid={this.state.validate.userMobileState === 'has-success'}
                                        invalid={this.state.validate.userMobileState === 'has-danger'}
                                        onChange={(e) => {
                                            this.validateUserMobile(e)
                                            this.handleInputChange(e)
                                        }}
                                        style={{ width: 180, textAlign: 'center' }} />
                                </div>
                                <div style={{ height: 10, padding: 10 }} >{this.state.showLoading ? <Spinner color="primary" /> : null}</div>
                            </FormGroup>
                        </Form>
                    </Flexbox>

                    <Flexbox flexGrow={0.8} justifyContent="center">
                        <Button onClick={this.doRegisterFunction} style={{ width: 120, height: 40, backgroundColor: '#0635c9' }}>تأیید</Button>
                    </Flexbox>
                </Flexbox>
            </div>
        );
    }
}
export default Start;