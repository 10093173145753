import React from 'react';
import { Button } from 'reactstrap';
import {
    isMobile
} from "react-device-detect";
import Flexbox from 'flexbox-react';
import HorizontalScroll from 'react-scroll-horizontal';
// import WebView from '../WebView';
import './Banner.css';

class Banner extends React.Component {

    render() {
        if (isMobile) {
            return (
                <div style={{ padding: 10 }}>
                    <div className="bannerCard1" onClick={() => this.props.parentMethod(51)}>
                        <Button onClick={() => this.props.parentMethod(51)} style={{ width: 80, height: 30, backgroundColor: '#0635c9', margin: 10 }}>ببینید</Button>
                    </div>
                    <div className="bannerCard2" onClick={() => this.props.parentMethod(52)}>
                        <Button onClick={() => this.props.parentMethod(52)} style={{ width: 80, height: 30, backgroundColor: '#0635c9', margin: 10 }}>بخوانید</Button>
                    </div>
                    <div className="bannerCard3" onClick={() => this.props.parentMethod(53)}>
                        <Button onClick={() => this.props.parentMethod(53)} style={{ width: 80, height: 30, backgroundColor: '#0635c9', margin: 10 }}>بخوانید</Button>
                    </div>
                    <div className="bannerCard4" onClick={() => this.props.parentMethod(54)}>
                        <Button onClick={() => this.props.parentMethod(54)} style={{ width: 80, height: 30, backgroundColor: '#0635c9', margin: 10 }}>بخوانید</Button>
                    </div>
                    <div className="bannerCard5" onClick={() => this.props.parentMethod(55)}>
                        <Button onClick={() => this.props.parentMethod(55)} style={{ width: 80, height: 30, backgroundColor: '#0635c9', margin: 10 }}>ببینید</Button>
                    </div>
                    <div className="bannerCard6" onClick={() => this.props.parentMethod(56)}>
                        <Button onClick={() => this.props.parentMethod(56)} style={{ width: 80, height: 30, backgroundColor: '#0635c9', margin: 10 }}>خرید کنید</Button>
                    </div>
                </div>);
        } else {
            return (
                <Flexbox flexDirection="row" minWidth="100vw" minHeight="170px">
                    <Flexbox flexGrow={1}>
                        <HorizontalScroll
                            pageLock={true}
                            reverseScroll={true}>
                            <div className="bannerCard11" onClick={() => this.props.parentMethod(51)}>
                                <Button onClick={() => this.props.parentMethod(51)} style={{ width: 80, height: 30, backgroundColor: '#0635c9', margin: 10 }}>ببینید</Button>
                            </div>
                            <div className="bannerCard22" onClick={() => this.props.parentMethod(52)}>
                                <Button onClick={() => this.props.parentMethod(52)} style={{ width: 80, height: 30, backgroundColor: '#0635c9', margin: 10 }}>بخوانید</Button>
                            </div>
                            <div className="bannerCard33" onClick={() => this.props.parentMethod(53)}>
                                <Button onClick={() => this.props.parentMethod(53)} style={{ width: 80, height: 30, backgroundColor: '#0635c9', margin: 10 }}>بخوانید</Button>
                            </div>
                            <div className="bannerCard44" onClick={() => this.props.parentMethod(54)}>
                                <Button onClick={() => this.props.parentMethod(54)} style={{ width: 80, height: 30, backgroundColor: '#0635c9', margin: 10 }}>بخوانید</Button>
                            </div>
                            <div className="bannerCard55" onClick={() => this.props.parentMethod(55)}>
                                <Button onClick={() => this.props.parentMethod(55)} style={{ width: 80, height: 30, backgroundColor: '#0635c9', margin: 10 }}>ببینید</Button>
                            </div>
                            <div className="bannerCard66" onClick={() => this.props.parentMethod(56)}>
                                <Button onClick={() => this.props.parentMethod(56)} style={{ width: 80, height: 30, backgroundColor: '#0635c9', margin: 10 }}>خرید کنید</Button>
                            </div>
                        </HorizontalScroll>
                    </Flexbox>
                </Flexbox>
            );
        }
    }
}
export default Banner;