import React from 'react';
import Axios from 'axios';
import Iframe from 'react-iframe';
import { isMobile } from "react-device-detect";

// import './Support.css';
var htmlDoc = { __html: "www.google.com" };
class Support extends React.Component {
    constructor(props) {
        super(props);

        this.state = {

            iframeLoaded: false,
        }
    }

    handleLoadedIframe = () => {
        this.setState({ iframeLoaded: true })
    }

    render() {
        return (
            <div style={{width: isMobile ? '100%' : 450}}>
                <Iframe
                    url='https://api.kalabisim.com/support'
                    position="absolute"
                    width="100%"
                    id="myId"
                    className="myClassname"
                    height="100%"
                    frameBorder="0"
                    styles={{ height: "25px" }} />
            </div>
        );
    }
}
export default Support;